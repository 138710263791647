import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import sceneConstructor from "./scene";
import { addSceneApi } from "../../redux/actions/addSceneApi";

import styles from "./scena.module.scss";

const initCameraPosition = [0, 50, 200];
const initModelPosition = [0, 0, 0];

const Scena = ({
  step,
  addSceneApi,
  sceneApi,
  from,
  departing,
  returning,
  to,
  ...props
}) => {
  const block = useRef(null);
  const isNotCTA = step > 1 && step < 6;
  const isClear = step !== 3;
  const isBlur = step === 5;

  useEffect(() => {
    if (block.current) {
      const sceneInstance = new sceneConstructor({
        node: block.current,
        cameraPosition: initCameraPosition,
        modelPosition: initModelPosition
      });
      // sceneInstance.init();
      addSceneApi(sceneInstance);
      sceneInstance.animate();
    }
  }, [addSceneApi]);

  useEffect(() => {
    if (Object.keys(sceneApi).length !== 0) {
      sceneApi.fullSizePlanet = isNotCTA;
    }
  }, [sceneApi, isNotCTA]);

  useEffect(() => {
    if (Object.keys(sceneApi).length !== 0 && isClear) {
      sceneApi.clearScene();
    }
  }, [sceneApi, isClear]);

  useEffect(() => {
    if (Object.keys(sceneApi).length !== 0) {
      sceneApi.blurScene = isBlur;
    }
  }, [sceneApi, isBlur]);

  useEffect(() => {
    if (Object.keys(sceneApi).length !== 0) {
      if (step !== 3 && step < 5) {
        sceneApi.enabledRotation();
      } else {
        // console.log(sceneApi);
        sceneApi.disebledRotation();
      }
    }
  }, [step, sceneApi]);

  useEffect(() => {
    if (from && sceneApi.sphereProperties) {
      sceneApi.onSelectFrom(from);
    }
  }, [from, sceneApi]);

  useEffect(() => {
    if (departing && sceneApi.sphereProperties) {
      sceneApi.onSelectPreparing();
    }
  }, [departing, sceneApi]);

  useEffect(() => {
    if (returning && sceneApi.sphereProperties) {
      sceneApi.onSelectReturning();
    }
  }, [returning, sceneApi]);

  useEffect(() => {
    if (to && sceneApi.sphereProperties) {
      sceneApi.onSelectTo(to ? [60, -95] : null);
    }
  }, [to, sceneApi]);

  const renderStyle = {
    WebkitFilter: "blur(3px)",
    filter: "blur(3px)"
  };

  return (
    <div
      style={step === 5 ? renderStyle : null}
      className={styles.scena}
      ref={block}
    ></div>
  );
};

const mapStateToProps = ({ step, sceneApi, travelPlan }) => ({
  step: step.currentStep,
  sceneApi,
  from: travelPlan.from.latlng,
  to: travelPlan.to.length ? true : false,
  departing: Boolean(
    travelPlan.DEPARTING_DATE_month &&
      travelPlan.DEPARTING_DATE_day &&
      travelPlan.DEPARTING_DATE_year
  ),
  returning: Boolean(
    travelPlan.RETURNING_DATE_month &&
      travelPlan.RETURNING_DATE_day &&
      travelPlan.RETURNING_DATE_year
  )
});
const mapDispatchToProps = {
  addSceneApi
};

export default connect(mapStateToProps, mapDispatchToProps)(Scena);
