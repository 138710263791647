/* eslint-disable */
const EASING = function(){};

Object.assign( EASING.prototype, {

  'inQuad': function( current, total ) {

    let b = 0;
    let c = 1;
    let d = total;
    let t = current;

    return c*(t/=d)*t + b;

  },
  'inCubic': function( current, total ) {

    let b = 0;
    let c = 1;
    let d = total;
    let t = current;

    return c*(t/=d)*t*t + b;

  },
  'inQuatr': function( current, total ) {

    let b = 0;
    let c = 1;
    let d = total;
    let t = current;

    return c*(t/=d)*t*t*t + b;

  },
  'inQuint': function( current, total ) {

    let b = 0;
    let c = 1;
    let d = total;
    let t = current;

    return c*(t/=d)*t*t*t*t + b;

  },
  'inSine': function( current, total ) {

    let b = 0;
    let c = 1;
    let d = total;
    let t = current;

    return -c * Math.cos(t/d * (Math.PI/2)) + c + b;

  },
  'inExpo': function( current, total ) {

    let b = 0;
    let c = 1;
    let d = total;
    let t = current;

    return (t==0) ? b : c * Math.pow(2, 10 * (t/d - 1)) + b;

  },
  'inCirc': function( current, total ) {

    let b = 0;
    let c = 1;
    let d = total;
    let t = current;

    return -c * (Math.sqrt(1 - (t/=d)*t) - 1) + b;

  },
  'inElastic': function( current, total ) {

    let b = 0;
    let c = 1;
    let d = total;
    let t = current;

    let s=1.70158;let p=0;let a=c;
    if (t==0) return b;  if ((t/=d)==1) return b+c;  if (!p) p=d*.3;
    if (a < Math.abs(c)) {
      a=c; let s=p/4;
    } else {
      s = p/(2*Math.PI) * Math.asin (c/a);
    }
    return -(a*Math.pow(2,10*(t-=1)) * Math.sin( (t*d-s)*(2*Math.PI)/p )) + b;

  },
  'inBack': function( current, total ) {

    let b = 0;
    let c = 1;
    let d = total;
    let t = current;

    if (s == undefined) s = 1.70158;
    return c*(t/=d)*t*((s+1)*t - s) + b;

  },
  'inBounce': function( current, total ) {

    let b = 0;
    let c = 1;
    let d = total;
    let t = current;

    return c - this.outBounce( current, total ) + b;

  },

  'outQuad': function( current, total ) {

    let b = 0;
    let c = 1;
    let d = total;
    let t = current;

    return -c *(t/=d)*(t-2) + b;

  },
  'outCubic': function( current, total ) {

    let b = 0;
    let c = 1;
    let d = total;
    let t = current;

    return c*((t=t/d-1)*t*t + 1) + b;

  },
  'outQuatr': function( current, total ) {

    let b = 0;
    let c = 1;
    let d = total;
    let t = current;

    return -c * ((t=t/d-1)*t*t*t - 1) + b;

  },
  'outQuint': function( current, total ) {

    let b = 0;
    let c = 1;
    let d = total;
    let t = current;

    return c*((t=t/d-1)*t*t*t*t + 1) + b;

  },
  'outSine': function( current, total ) {

    let b = 0;
    let c = 1;
    let d = total;
    let t = current;

    return c * Math.sin(t/d * (Math.PI/2)) + b;

  },
  'outExpo': function( current, total ) {

    let b = 0;
    let c = 1;
    let d = total;
    let t = current;

    return (t==d) ? b+c : c * (-Math.pow(2, -10 * t/d) + 1) + b;

  },
  'outCirc': function( current, total ) {

    let b = 0;
    let c = 1;
    let d = total;
    let t = current;

    return c * Math.sqrt(1 - (t=t/d-1)*t) + b;

  },
  'outElastic': function( current, total ) {

    let b = 0;
    let c = 1;
    let d = total;
    let t = current;

    let s=1.70158;let p=0;let a=c;
    if (t==0) return b;  if ((t/=d)==1) return b+c;  if (!p) p=d*.3;
    if (a < Math.abs(c)) { a=c; let s=p/4; }
    else s = p/(2*Math.PI) * Math.asin (c/a);
    return a*Math.pow(2,-10*t) * Math.sin( (t*d-s)*(2*Math.PI)/p ) + c + b;

  },
  'outBack': function( current, total ) {

    let b = 0;
    let c = 1;
    let d = total;
    let t = current;

    if (s == undefined) s = 1.70158;
    return c*((t=t/d-1)*t*((s+1)*t + s) + 1) + b;

  },
  'outBounce': function( current, total ) {

    let b = 0;
    let c = 1;
    let d = total;
    let t = current;

    if ((t/=d) < (1/2.75)) {
      return c*(7.5625*t*t) + b;
    } else if (t < (2/2.75)) {
      return c*(7.5625*(t-=(1.5/2.75))*t + .75) + b;
    } else if (t < (2.5/2.75)) {
      return c*(7.5625*(t-=(2.25/2.75))*t + .9375) + b;
    } else {
      return c*(7.5625*(t-=(2.625/2.75))*t + .984375) + b;
    }

  },

  'inOutQuad': function( current, total ) {

    let b = 0;
    let c = 1;
    let d = total;
    let t = current;

    if ((t/=d/2) < 1) return c/2*t*t + b;
    return -c/2 * ((--t)*(t-2) - 1) + b;

  },
  'inOutCubic': function( current, total ) {

    let b = 0;
    let c = 1;
    let d = total;
    let t = current;

    if ((t/=d/2) < 1) return c/2*t*t*t + b;
    return c/2*((t-=2)*t*t + 2) + b;

  },
  'inOutQuatr': function( current, total ) {

    let b = 0;
    let c = 1;
    let d = total;
    let t = current;

    if ((t/=d/2) < 1) return c/2*t*t*t*t + b;
    return -c/2 * ((t-=2)*t*t*t - 2) + b;

  },
  'inOutQuint': function( current, total ) {

    let b = 0;
    let c = 1;
    let d = total;
    let t = current;

    if ((t/=d/2) < 1) return c/2*t*t*t*t*t + b;
    return c/2*((t-=2)*t*t*t*t + 2) + b;

  },
  'inOutSine': function( current, total ) {

    let b = 0;
    let c = 1;
    let d = total;
    let t = current;

    return -c/2 * (Math.cos(Math.PI*t/d) - 1) + b;

  },
  'inOutExpo': function( current, total ) {

    let b = 0;
    let c = 1;
    let d = total;
    let t = current;

    if (t==0) return b;
    if (t==d) return b+c;
    if ((t/=d/2) < 1) return c/2 * Math.pow(2, 10 * (t - 1)) + b;
    return c/2 * (-Math.pow(2, -10 * --t) + 2) + b;

  },
  'inOutCirc': function( current, total ) {

    let b = 0;
    let c = 1;
    let d = total;
    let t = current;

    if ((t/=d/2) < 1) return -c/2 * (Math.sqrt(1 - t*t) - 1) + b;
    return c/2 * (Math.sqrt(1 - (t-=2)*t) + 1) + b;

  },
  'inOutElastic': function( current, total ) {

    let b = 0;
    let c = 1;
    let d = total;
    let t = current;

    let s=1.70158;let p=0;let a=c;
    if ( t==0){  return b; }
    if ((t/=d/2)==2){  return b+c; }
    if (!p) { p=d*(.3*1.5); }
    if (a < Math.abs(c)) { a=c; s=p/4; }
    else s = p/(2*Math.PI) * Math.asin (c/a);
    if (t < 1) return -.5*(a*Math.pow(2,10*(t-=1)) * Math.sin( (t*d-s)*(2*Math.PI)/p )) + b;
    return a*Math.pow(2,-10*(t-=1)) * Math.sin( (t*d-s)*(2*Math.PI)/p )*.5 + c + b;

  },
  'inOutBack': function( current, total ) {

    let b = 0;
    let c = 1;
    let d = total;
    let t = current;

    if (s == undefined) s = 1.70158;
    if ((t/=d/2) < 1) return c/2*(t*t*(((s*=(1.525))+1)*t - s)) + b;
    return c/2*((t-=2)*t*(((s*=(1.525))+1)*t + s) + 2) + b;

  },
  'inOutBounce': function( current, total ) {

    let b = 0;
    let c = 1;
    let d = total;
    let t = current;

    if (t < d/2) return this.inBounce( current*2, total ) * .5 + b;
    return this.outBounce( current*2 - total, total ) * .5 + c*.5 + b;

  },

  'linear': function( current, total ) {
    return current / total;
  },

});

export default new EASING();
