import React, { useState, useCallback, useEffect, memo } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import styles from "./fpfsstepfour.module.scss";

import Title from "../Title/Title";
import TextInput from "../TextInput/TextInput";
import Traveler from "../Traveler/Traveler";
import MySelect from "../MySelect/MySelect";

import { addTraveler } from "../../redux/actions/travelers";
import { blockNextStep, setBlockFields } from "../../redux/actions/step";

import TagManager from "react-gtm-module";

const initState = {
  fname: "",
  lname: "",
  dateMonth: "",
  dateDay: "",
  dateYear: ""
};

const opened = {
  mm: false,
  dd: false,
  yy: false
};

const FPFSStepFour = ({
  intl,
  travelers,
  addTraveler,
  mounted,
  step,
  blockNextStep,
  setBlockFields,
  ...props
}) => {
  const [traveler, setTraveler] = useState(initState);
  const [errors, setErrors] = useState({});
  const [blur, setBlur] = useState(false);
  const [openedSelect, setOpenedSelect] = useState(opened);

  let now = new Date();

  // =====================================START ADD GTM
  useEffect(() => {
    if (mounted && window.ga) {
      const data = {
        dataLayer: {
          hitType: "event",
          eventCategory: "FunnelStep",
          eventAction: "view",
          eventLabel: "Whos Traveling"
        }
      };
      TagManager.dataLayer(data);

      // window.ga("send", {
      //   hitType: "event",
      //   eventCategory: "FunnelStep",
      //   eventAction: "view",
      //   eventLabel: "Whos Traveling"
      // });
    }
  }, [mounted]);
  // =====================================END ADD GTM

  const togggleBlur = useCallback(() => {
    setBlur(prev => !prev);
  }, []);

  useEffect(() => {
    if (step === 4) {
      travelers.length === 0 ? blockNextStep(true) : blockNextStep(false);
    }
  }, [blockNextStep, step, travelers.length]);

  useEffect(() => {
    if (step === 4) {
      setBlockFields(true);
    }
  }, [step, setBlockFields]);

  useEffect(() => {
    if (step === 4) {
      traveler.lname !== "" && blur
        ? setOpenedSelect(prev => ({ ...prev, mm: true }))
        : setOpenedSelect(prev => ({ ...prev, mm: false }));

      traveler.dateMonth !== ""
        ? setOpenedSelect(prev => ({ ...prev, dd: true }))
        : setOpenedSelect(prev => ({ ...prev, dd: false }));

      traveler.dateDay !== ""
        ? setOpenedSelect(prev => ({ ...prev, yy: true }))
        : setOpenedSelect(prev => ({ ...prev, yy: false }));
    }
  }, [blur, step, traveler]);

  const validation = data => {
    const er = {};

    if (!data.fname) er.fname = "FIRST NAME CANNOT BE BLANK";
    if (!data.lname) er.lname = "LAST NAME CANNOT BE BLANK";

    if (!data.dateMonth) er.dateMonth = "month cannot be blank";
    if (!data.dateDay) er.dateDay = "day cannot be blank";
    if (!data.dateYear) er.dateYear = "year cannot be blank";

    return er;
  };

  const handleChange = useCallback(
    ({ target }) => {
      setErrors(() => {
        for (let i in errors) {
          if (i === target.name) {
            delete errors[i];
          }
        }
        return { ...errors };
      });

      setTraveler({
        ...traveler,
        [target.name]: target.value
      });
    },
    [traveler, errors]
  );

  const handleSelDate = useCallback(
    () => e => {
      setErrors(() => {
        for (let i in errors) {
          if (i === e.dataset.name) {
            delete errors[i];
          }
        }
        return { ...errors };
      });

      setTraveler({
        ...traveler,
        [e.dataset.name]: e.dataset.value
      });
    },
    [errors, traveler]
  );

  const handleSubmitt = useCallback(
    e => {
      e.preventDefault();
      const er = validation(traveler);
      setErrors({ ...errors, ...er });

      if (Object.keys(er).length === 0) {
        addTraveler(traveler);
        setTraveler(initState);
        return;
      }
    },
    [traveler, errors, setTraveler, setErrors, addTraveler]
  );

  return (
    <div
      className={
        mounted
          ? `${styles.container} ${styles.container_active}`
          : styles.container
      }
    >
      <Title title={intl.formatMessage({ id: "step.four.title" })} />
      <div>
        {Object.keys(errors).length !== 0 ? (
          <div className={styles.error}>
            <span className={styles.error__item}>{errors.fname}</span>
            <span className={styles.error__item}>{errors.lname}</span>
            <span className={styles.error__item}>
              {errors.dateMonth || errors.dateDay || errors.dateYear
                ? "PLEASE ENTERR BD BIRTH DATE"
                : null}
            </span>
          </div>
        ) : null}

        <form
          className={
            mounted ? `${styles.form} ${styles.form_active}` : styles.form
          }
        >
          <div className={styles.form__item}>
            <h3 className={styles.title}>
              {intl.formatMessage({ id: "step.four.travelersName" })}
            </h3>
            <TextInput
              type="text"
              name="fname"
              value={traveler.fname}
              placeholder={intl.formatMessage({ id: "step.four.FName" })}
              onChange={handleChange}
              error={errors.fname}
            />
            <TextInput
              type="text"
              name="lname"
              value={traveler.lname}
              placeholder={intl.formatMessage({ id: "step.four.LName" })}
              onChange={handleChange}
              onBlur={togggleBlur}
              error={errors.lname}
            />
          </div>
          <div className={styles.form__item}>
            <h3 className={styles.title}>
              {intl.formatMessage({ id: "step.four.travelersBirthDate" })}
            </h3>
            <div className={styles.dateCont}>
              <div className={styles.dateCont__min}>
                <MySelect
                  val={traveler.dateMonth}
                  name="dateMonth"
                  type="number"
                  min="1"
                  max="12"
                  placeholder={intl.formatMessage({ id: "step.four.month" })}
                  handleChange={handleSelDate()}
                  disabled={false}
                  opened={openedSelect.mm}
                  error={errors.dateMonth}
                />
              </div>

              <div className={styles.dateCont__min}>
                <MySelect
                  val={traveler.dateDay}
                  name="dateDay"
                  type="number"
                  min="1"
                  max="31"
                  placeholder={intl.formatMessage({ id: "step.four.day" })}
                  handleChange={handleSelDate()}
                  disabled={false}
                  opened={openedSelect.dd}
                  error={errors.dateDay}
                />
              </div>

              <div className={styles.dateCont__max}>
                <MySelect
                  val={traveler.dateYear}
                  name="dateYear"
                  type="number"
                  min={now.getFullYear() - 100}
                  max={now.getFullYear()}
                  placeholder={intl.formatMessage({ id: "step.four.year" })}
                  handleChange={handleSelDate()}
                  disabled={false}
                  opened={openedSelect.yy}
                  error={errors.dateYear}
                />
              </div>
            </div>

            <button className={styles.btn} onClick={handleSubmitt}>
              {intl.formatMessage({ id: "step.four.add" })}
            </button>
          </div>
        </form>
        {/* ====================================================================доделать скролл */}
        {travelers.length > 0 ? (
          <div className={styles.trav_cont + " dropdownSelect"}>
            {travelers.map(t => (
              <Traveler
                key={t.id}
                id={t.id}
                fname={t.fname}
                lname={t.lname}
                month={t.dateMonth}
                day={t.dateDay}
                year={t.dateYear}
              />
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = ({ traveler, step }) => ({
  travelers: traveler.travelers,
  step: step.currentStep
});

const mapDispathToProps = { addTraveler, blockNextStep, setBlockFields };

export default connect(
  mapStateToProps,
  mapDispathToProps
)(injectIntl(memo(FPFSStepFour)));
