import { handleError, handleResponse } from "./apiUtils";
import { API_URL } from "../constants/utils";

const baseUrl = `${API_URL}contactus`;
const travelPlanUrl = `${API_URL}travelplan`;
const fearsUrl = `${API_URL}fears`;

export const sendMessage = contacts => {
  return fetch(baseUrl, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json"
    },
    // body: JSON.stringify(contacts)
    body: JSON.stringify({ ...contacts, sa: "SA" }) //========================for page sa.iwaretravel.ca
  })
    .then(handleResponse)
    .catch(handleError);
};

// ====================================================

export const sendTravelPlan = data => {
  return fetch(travelPlanUrl, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json"
    },
    // body: JSON.stringify(data)
    body: JSON.stringify({ ...data, sa: "SA" }) //========================for page sa.iwaretravel.ca
  })
    .then(handleResponse)
    .catch(handleError);
};

// ====================================================

export const sendFears = data => {
  return fetch(fearsUrl, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json"
    },
    // body: JSON.stringify(data)
    body: JSON.stringify({ ...data, sa: "SA" }) //========================for page sa.iwaretravel.ca
  })
    .then(handleResponse)
    .catch(handleError);
};
