import React, { memo } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./traveler.module.scss";

import { removeTraveler } from "../../redux/actions/travelers";

const Traveler = ({ id, fname, lname, month, day, year, removeTraveler }) => {
  const handleRemove = e => {
    removeTraveler(e.target.id);
  };

  return (
    <div className={styles.cont}>
      <div className={styles.cont__fname}>{fname}</div>
      <div className={styles.cont__lname}>{lname}</div>
      <div className={styles.cont__month}>{month}</div>
      <div className={styles.cont__day}>{day}</div>
      <div className={styles.cont__year}>{year}</div>
      <button className={styles.remove} id={id} onClick={handleRemove}>
        <img
          src="img/remove.png"
          srcSet="img/remove@2x.png 2x,
                  img/remove@3x.png 3x"
          alt="remove"
        />
      </button>
    </div>
  );
};

Traveler.propTypes = {
  id: PropTypes.string,
  fname: PropTypes.string,
  lname: PropTypes.string
};

const mapStateToProps = () => ({});
const mapDispatchToProps = { removeTraveler };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(Traveler));
