import React from "react";
import { Route, Switch } from "react-router-dom";

import Header from "./components/Header/Header";
import Navigation from "./components/Navigation/Navigation";

import HomePage from "./pages/HomePage";
import ContactUsPage from "./pages/ContactUsPage";
import PageNotFound from "./pages/PageNotFound";
import Thanks from "./pages/Thanks";

import { PATH } from "./constants/utils";

function App() {
  return (
    <>
      <Header />
      <Navigation />
      <Switch>
        <Route exact path={PATH} component={HomePage} />
        <Route path={`${PATH}contacts`} component={ContactUsPage} />
        <Route path={`${PATH}thanks`} component={Thanks} />
        <Route component={PageNotFound} />
      </Switch>
    </>
  );
}

export default App;
