import React from "react";
import useHeight from "../../hooks/useHeight";
import styles from "./container.module.scss";

const Container = ({ children }) => {
  let height = useHeight();

  const renderStyle = {
    height: `${height}px`
  };

  return (
    <div style={renderStyle} className={styles.container}>
      {children}
    </div>
  );
};

export default Container;
