import { combineReducers } from "redux";
import step from "./stepReducer";
import purpose from "./purposeReducer";
import traveler from "./travelerReducer";
import country from "./countryReducer";
import apiStatus from "./apiStatusReducer";
import fears from "./fearsReducer";
import nav from "./navReducer";
import fp from "./fpApiReducer";
import travelPlan from "./travelPlanReducer";
import sceneApi from "./sceneApiReducer";
import blockStep from "./blockStepReducer";
import blockFields from "./blockFieldsTravelPlan";

export default combineReducers({
  step,
  purpose,
  traveler,
  country,
  apiStatus,
  fears,
  nav,
  fp,
  travelPlan,
  sceneApi,
  blockStep,
  blockFields
});
