import ACTIONS from "../../constants/constants";

const initianState = false;

export default (state = initianState, action) => {
  if (action.type === ACTIONS.BLOCK_TPFIELDS) {
    return action.active;
  }
  return state;
};
