export default {
  "en-CA": {
    // ============================NAVIGATION
    "nav.home": "Get a quote",
    "nav.contacts": "Contact Us",
    "nav.license": "iWareTravel Insurance is licensed by:",

    // =========================stepNavigation
    "step.move.next": "CONTINUE",
    "step.move.prev": "PREVIOUS",

    // =========================contact us link
    "contUsLink.title": "NEED HELP?",
    "contUsLink.subTitle": "CONTACT US",

    // =========================firstStep
    "step.first.title": "Safe Travels,",
    "step.first.subTitle": "Begin with Travel Insurance.",
    "step.first.linkTitle": "Get A Free",
    "step.first.linkSubTitle": "Travel Insurance Quote",

    "step.first.insuresLinkTitle": "Why Get Travel Insurance from Canada?",
    "step.first.insuresLinkList_f":
      " Direct Billing - you don’t need to pay out of pocket.",
    "step.first.insuresLinkList_s":
      "Already outside of your home country? No problem, you can still get coverage.",
    "step.first.insuresLinkList_t":
      "Get Medical Coverage for Pre-existing Conditions.",

    // =========================twoStep
    "step.two.title": "Purpose of Travels to Canada?",
    "step.two.treveler_1": "OUTDOOR ADVENTURE",
    "step.two.treveler_2": "VISITING FAMILY",
    "step.two.treveler_3": "STUDYING IN CANADA",
    "step.two.treveler_4": "EXPLORING OPPORTUNITIES",

    // ===============================threeStep

    "step.three.title": "Travel Plans?",
    "step.three.from": "TRAVELING FROM",
    "step.three.to": "VISITING PROVINCE(S)",
    "step.three.departingDate": "DEPARTING DATE",
    "step.three.returningDate": "RETURNING DATE",
    "step.three.selectCountry": "Select Country",
    "step.three.chooseProvince": "Choose Province(s)",

    // =========================fourStep
    "step.four.title": "Who is Traveling?",
    "step.four.travelersName": "TRAVELERS NAME",
    "step.four.travelersBirthDate": "TRAVELERS BIRTH DATE",
    "step.four.FName": "First Name",
    "step.four.LName": "Last Name",
    "step.four.month": "MM",
    "step.four.day": "DD",
    "step.four.year": "YYYY",
    "step.four.add": "ADD TRAVELER",

    // ==========================fiveStep

    "step.five.title": "Here’s Your Quote!",
    "step.five.string": "Choose Your Coverage Options then Select an Insurer",
    "step.five.btn": "I CHOOSE THIS POLICY",

    // ==========================contactDetailStep

    "step.contactDetail.title": "Almost Done!",
    "step.contactDetail.string":
      "Enter your contact details, to receive your quote.",
    "step.contactDetail.email": "Email",
    "step.contactDetail.phone": "Phone",
    "step.contactDetail.phoneor": "Phone or WeChat",
    "step.contactDetail.btn": "GET MY QUOTE",

    //=============================completeStep
    "step.complite.title": "Your Quote request is Complete",
    "step.complite.string":
      "An iWareTravel representative will be in touch with you shortify",
    "step.complite.btn": "Return",

    // ==============================firstPage Screen2
    "fp.screenTwo.title": "Why Get Travel Insurance?",
    "fp.screenTwo.titleTab1":
      "FOR COVERAGE OF PRE-EXISTING MEDICAL CONDITIONS.",
    "fp.screenTwo.titleTab2":
      "ALREADY STARTED YOUR TRAVELS? YOU CAN STILL GET TRAVEL INSURANCE!",
    "fp.screenTwo.titleTab3": "COVERAGE FOR LOST LUGGAGE.",
    "fp.screenTwo.titleTab4": "COVERAGE FOR MISSED FLIGHTS.",
    "fp.screenTwo.titleTab5": "COVERAGE FOR TRIP CANCELLATIONS.",

    "fp.screenTwo.fearFormTitle": "What’s your biggest travel fear?",
    "fp.screenTwo.submit": "Submit",
    "fp.screenTwo.selectAnsver": "Select your answer",

    "fp.screenTwo.fear5": "Forgot my pills for pre-existing conditions",
    "fp.screenTwo.fear3": "Missing Flights or Luggage",
    "fp.screenTwo.fear1": "Finding Assistance in case of an emergency",
    "fp.screenTwo.fear4": "A big medical bill paid out of my own pocket",
    "fp.screenTwo.fear2":
      "ALREADY STARTED YOUR TRAVELS? YOU CAN STILL GET TRAVEL INSURANCE!",

    // ==============================firstPage Screen3
    "fp.screenThree.title": "What Our Customers",
    "fp.screenThree.subtitle": "Are Saying About Us.",

    // ==============================firstPage Screen4
    "fp.screenFour.title": "About Us",
    "fp.screenFour.btn": "GET A FREE QUOTE NOW",
    "fp.screenFour.bold":
      "As an immigrant to Canada, I have family visiting me each year. One year, my dad was hospitalized for three days while visiting Canada, costing $13,000. Luckily I had bought travel insurance for him. It was the best investment I’ve ever made.",
    "fp.screenFour.norm":
      "Did you know, you can buy Travel Insurance from either your home country or your destination country? I learnt that it’s always best to buy travel insurance from your destination country. In Canada for example, you can get coverage for pre-existing medical conditions and receive better service, such as direct billing, which means you don’t need to pay out of your own pocket in the case of medical emergency.",

    // ==============================contact us page

    "contactUs.title": "Need to Connect With us?",
    "contactUs.subTitle": "You’re in the Right Place!",
    "contactUs.formTitle": "PLEASE SUBMIT THIS FORM TO CONNECT WITH US",
    "contactUs.fn": "*First Name",
    "contactUs.ln": "*Last Name",
    "contactUs.email": "*Email",
    "contactUs.message": "Your message",
    "contactUs.send": "SEND MESSAGE",

    // ==============================Thanks page
    "thanks.title": "Thank you for your submission.",
    "thanks.text":
      "An iWareTravel representative will be in touch with you shortly to assist you !",
    "thanks.btn": "Return",

    // ==================================================comments
    "comment.1":
      "I bought travel insurance through Lancy for my mom and my sister’s family when they came to visit me in Calgary. Great service, very reliable and accountable. You wont be disappointed.",
    "comment.2":
      "The comprehensive travel insurance products and professional considerate services of iWare Travel allows me to enjoy my trip in Canada worry free. Thank you Lancy, and I look forward to doing business with you next time!",
    "comment.3":
      "I am a visiting teacher in the university, I bought 3 policies from Lancy for myself and my families. She is always patient in answering all my questions and recommending the best fit travel insurance plan for us. I trust her.",

    "comment.name.1": "Dzhu",
    "comment.name.2": "Elizabeth",
    "comment.name.3": "Al",
    "comment.name.4": "Lee"
  },

  "ch-MA": {
    // ============================NAVIGATION
    "nav.home": "询个价",
    "nav.contacts": "联系我们",
    "nav.license": "iWare Travel的保险执照是通过以下公司发起并保证的",

    // =========================stepNavigation
    "step.move.prev": "上一步",
    "step.move.next": "下一步",

    // =========================contact us link
    "contUsLink.title": "需要帮忙？",
    "contUsLink.subTitle": "联系我们！",

    // =========================firstStep
    "step.first.title": "旅游保险，",
    "step.first.subTitle": "让你的旅途后顾无忧",

    "step.first.linkTitle": "获取免费报价",
    "step.first.linkSubTitle": " ",

    "step.first.insuresLinkTitle": "为什么从加拿大买旅游保险？",
    "step.first.insuresLinkList_f":
      "直赔功能-住院不用自掏腰包，保险公司直接买单",
    "step.first.insuresLinkList_s":
      "人已经在加拿大无法再从国内买保险？没问题，我们帮你从加拿大买",
    "step.first.insuresLinkList_t":
      "既往病史（如慢性病）一旦发作不可小觑，你确定你的保险包含这部分吗？",

    // =========================twoStep===
    "step.two.title": "来加拿大干点啥",
    "step.two.treveler_1": "旅游探险",
    "step.two.treveler_2": "探亲访友",
    "step.two.treveler_3": "留学夏令营",
    "step.two.treveler_4": "寻找机会",

    // ===============================threeStep===

    "step.three.title": "旅行计划",
    "step.three.from": "从哪来",
    "step.three.to": "到哪去",
    "step.three.departingDate": "出发日期",
    "step.three.returningDate": "返回日期",
    "step.three.selectCountry": "选择国家",
    "step.three.chooseProvince": "选择省份",

    // =========================fourStep===
    "step.four.title": "人在旅途",
    "step.four.travelersName": "旅行者姓名",
    "step.four.travelersBirthDate": "生日",
    "step.four.FName": "名",
    "step.four.LName": "姓",
    "step.four.month": "月",
    "step.four.day": "日",
    "step.four.year": "年",
    "step.four.add": "添加",

    // ==========================fiveStep

    "step.five.title": "這是您的報價！",
    "step.five.string": "選擇您的承保範圍選項，然後選擇一家保險公司",
    "step.five.btn": "我選擇此政策",

    // ==========================contactDetailStep===

    "step.contactDetail.title": "马上就好了！",
    "step.contactDetail.string": "报价发到哪里呢？",
    "step.contactDetail.email": "邮箱",
    "step.contactDetail.phone": "电话",
    "step.contactDetail.phoneor": "电话或微信",
    "step.contactDetail.btn": "获取报价",

    //=============================compleateStep===

    "step.complite.title": "我们已经收到你的报价需求",
    "step.complite.string": "我们的客服代表很快就会跟你联系",
    "step.complite.btn": "返回",

    // ==============================firstPage Screen2===

    "fp.screenTwo.title": "为什么要买旅游保险？",

    "fp.screenTwo.titleTab1": "万一既往病史（如慢性病）发作需要紧急就医",
    "fp.screenTwo.titleTab2":
      "人已经在境外，不能再从国内买保险了？没关系，我们帮你搞定",
    "fp.screenTwo.titleTab3": "行李丢失",
    "fp.screenTwo.titleTab4": "航班延误",
    "fp.screenTwo.titleTab5": "行程取消",

    "fp.screenTwo.fearFormTitle": "旅行中最让你担心的是什么？",
    "fp.screenTwo.submit": "提交",
    "fp.screenTwo.selectAnsver": "请选择",

    "fp.screenTwo.fear5": "忘记带平时定期服用的药",
    "fp.screenTwo.fear3": "错过航班或丢失行李",
    "fp.screenTwo.fear1": "发生紧急情况的时候找不到帮助",
    "fp.screenTwo.fear4": "需要自掏腰包支付巨额医药费",
    "fp.screenTwo.fear2": "已经开始您的旅行了吗？您仍然可以获得旅行保险！",

    // ==============================firstPage Screen3===
    "fp.screenThree.title": "我们的客户怎么说",
    "fp.screenThree.subtitle": " ",

    // ==============================firstPage Screen4
    "fp.screenFour.title": "关于我们",
    "fp.screenFour.btn": "免费报价",

    "fp.screenFour.bold":
      "作为一个加拿大移民,我父母每年都会来加拿大看我住上几个月。那一年,父亲因为急病住了三天医院花 费13000加币。万幸的是我买了旅游保险。这恐怕是我做的最好的投资之一了。",
    "fp.screenFour.norm":
      "你知道吗?旅游保险既可以从出发地购买也可以从目的国购买?我的经验是从目的国购买更好一些。拿加 拿大来说,游客如果从加拿大购买旅游保险可以获得更好的服务,比如直赔服务(保险公司和医院直接结 算,不用自掏腰包垫付,省却理赔的烦恼),或者更全面的保障(比如既往病史的保障)",

    // ==============================contact us page

    "contactUs.title": "联系我们？",
    "contactUs.subTitle": "来对地方了",
    "contactUs.formTitle": "Please submit this form to connect with us",
    "contactUs.fn": "名",
    "contactUs.ln": "姓",
    "contactUs.email": "邮箱",
    "contactUs.message": "想说点啥？",
    "contactUs.send": "发送信息",

    // ==============================Thanks page
    "thanks.title": "感謝您提交。",
    "thanks.text": "iWareTravel代表將盡快與您聯繫，以協助您提出索賠",
    "thanks.btn": "返回",

    // ==================================================comments
    "comment.1":
      "妈妈和姐姐一家来加拿大都在Lancy这里买的保险。服务好，人品好，信誉有保证。不会让大家失望的。",
    "comment.2":
      "iWare Travel以其专业全面的产品种类，贴心细致的顾问服务，让人徜徉在加拿大的极致景致中可以尽兴无忧。感谢之余，期待下次旅行再相会。",
    "comment.3":
      "I am a visiting teacher in the university, I bought 3 policies from Lancy for myself and my families. She is always patient in answering all my questions and recommending the best fit travel insurance plan for us. I trust her.",

    "comment.name.1": "Dzhu 说",
    "comment.name.2": "Elizabeth说",
    "comment.name.3": "Al说",
    "comment.name.4": "Lee说"
  }
};
