/* eslint-disable */

import * as THREE from 'three';
import { EventDispatcher } from 'three';
import Instruction from "./instruction";
import EASING from './easing';
import {
    getPointsOverSphere
} from './camera.traveler.utils.js';

export default class CameraTraveler extends EventDispatcher {

    canvas = null;
    controls = null;
    camera = null;
    inAction = false;
    animations = new Instruction();

    debugSphere = null;

    onstartControlsEnabledState = false;
    lastPointsConfig = null;

    EASING = EASING;

    constructor( options ){
        super();
        this.controls = options.controls || null;
        this.camera = options.camera || null;
        this.canvas = options.canvas || null;

    }

    animate( ){
        this.animations.run();
    }

    travelAroundBoundingSphere( config, THREEMathSphere, crossPointsCount, debugSphere ){
        const options = { 
            boundingSphere: THREEMathSphere, 
            cameraPosition_start: new THREE.Vector3().fromArray( config.firstConcatPos ? config.firstConcatPos.camera : config.start.camera ), 
            cameraPosition_end: new THREE.Vector3().fromArray( config.end.camera ),
            targetPosition_start: new THREE.Vector3().fromArray( config.firstConcatPos ? config.firstConcatPos.controls : config.start.controls ),
            targetPosition_end: new THREE.Vector3().fromArray( config.end.controls ),
            crossPointsCount,
        };

        const { start, end } = getPointsOverSphere( options );

        const points = getPointsOverSphere(options)
        
        // config.start = start;

        // if(config.concatPos) {
        //     config.end ={
        //         camera: config.concatPos.camera,
        //         controls: config.concatPos.controls
        //     }
        // } else if(config.firstConcatPos) {
        //     config.start.crossPoints.out = [
        //         config.firstConcatPos,                
        //         ...config.start.crossPoints.out
        //     ]
        // } else {
        //     config.end = end 
        // }
        
        // // console.log(start, end)

        // return this.travel( config );

    }

    travel( config ) {
        /**

            config => {

                start: {
                    camera: [ x, y, z ],
                    controls: [ x, y, z ],
                },

                end: {
                    camera: [ x, y, z ],
                    controls: [ x, y, z ],
                },

                speed: travelSpeed,

                onProgress: ( alphaWithEasing, alphaLinear ) => {

                    onProgressCallback function;

                }

            }

        */

        
        let _actionFoo = config.onProgress ? config.onProgress : f => f;
        let _speed = config.speed ? config.speed : 15;
        let _start = config.start ? config.start : { camera: [ 0, 0, 1 ], controls: [ 0.000001, 0, 0 ] };
        let _end = config.end ? config.end : { camera: [ 1, 0, 0 ], controls: [ 0, 0, 0.000001 ] };
        let _easing = config.easing ? config.easing : 'inOutQuint';

        return new Promise( ( resolve, reject ) => {

            if ( this.inAction ) {

                reject({
                    reason: 'camera ready in action',
                });

            } else {

                const start = JSON.parse( JSON.stringify(  _start ) );
                const end = JSON.parse( JSON.stringify(  _end ) );
                const speed = _speed;
                const easing = _easing;

                const actionFoo = _actionFoo || function (alpha) { };

                if (!end.points) {
                    this.dispatchEvent('scene:points.changed', {});
                }

                this.dispatchEvent('camera:start.travel', {});

                const maxBlur = 5;

                this.cameraTravel({

                    start: start,
                    end: end,
                    speed: speed,
                    concatPos: config.concatPos,
                    easing: easing,

                    onProgress: ( alpha ) => {

                        actionFoo(alpha);

                        if( this.canvas ){
                            this.canvas.style.filter = 'blur(' + Math.floor( maxBlur * Math.sin( alpha * Math.PI ) ) + 'px)';
                        }

                    },

                    onEnd: eventResult => {

                        if( this.canvas ){
                            this.canvas.style.filter = 'blur(0px)';
                        }

                        const finalEvent = {
                            from: { sceneData: eventResult.start },
                            to: { sceneData: eventResult.end },
                            steps: eventResult.steps,
                        };

                        this.lastPointsConfig = end;

                        this.dispatchEvent('camera:end.travel', {});

                        this.dispatchEvent('scene:change.position', finalEvent);

                        resolve(finalEvent);
                    },
                });

            }
        });
    }

    onEnd(){
        this.inAction = false;
        this.controls.enabled = this.onstartControlsEnabledState;

    }
    
    onStart(){
        this.inAction = true;
        this.onstartControlsEnabledState = this.controls.enabled;
        this.controls.enabled = false;
    }

    cameraTravel( config ) {

        this.onStart();

        const start = {
            camera: new THREE.Vector3().fromArray(config.start.camera),
            controls: new THREE.Vector3().fromArray(config.start.controls),
        };

        const end = {
            camera: new THREE.Vector3().fromArray(config.end.camera),
            controls: new THREE.Vector3().fromArray(config.end.controls),
        };

        const curveCameraArray = [ start.camera.clone() ];
        const curveControlsArray = [ start.controls.clone() ];

        if (config.start.crossPoints) {
            for (const nextCrossPoint of config.start.crossPoints.out) {
                curveCameraArray.push(new THREE.Vector3().fromArray(nextCrossPoint.camera));
                curveControlsArray.push(new THREE.Vector3().fromArray(nextCrossPoint.controls));
            }
        }

        if (config.end.crossPoints) {
            for (const nextCrossPoint of config.end.crossPoints.in) {
                curveCameraArray.push(new THREE.Vector3().fromArray(nextCrossPoint.camera));
                curveControlsArray.push(new THREE.Vector3().fromArray(nextCrossPoint.controls));
            }
        }

        curveCameraArray.push(end.camera.clone());
        curveControlsArray.push(end.controls.clone());

        const nextEasingFoo = ( config.easing in EASING ) ? config.easing : 'linear';

        const curveCamera = new THREE.CatmullRomCurve3( curveCameraArray );
        const curveControls = new THREE.CatmullRomCurve3( curveControlsArray );

        const maxFrames = 400;
        const calculatedFrames = Math.ceil( start.camera.distanceTo(end.camera) / config.speed );
        let frames = ( calculatedFrames > maxFrames ) ? maxFrames : calculatedFrames;

        let currentFrame = 0;

        const travelCameraPoints = [];
        const travelControlsPoints = [];


        try{ this.EASING[ nextEasingFoo ]( frames/2, frames ); } catch( err ){ console.error( err ); throw err; }

        for( let i = 0; i < frames; i++ ){
            travelCameraPoints.push( curveCamera.getPoint( EASING[ nextEasingFoo ]( i, frames ) ) );
            travelControlsPoints.push(  curveControls.getPoint( EASING[ nextEasingFoo ]( i, frames ) )  );
        }


        this.animations.add( 'cameraTravel', () => {
            
            const linearAlpha = currentFrame / frames;

            const currentFrameAlpha = EASING[ nextEasingFoo ]( currentFrame, frames );

            if ( currentFrame < frames ) {

                // if( this.debugSphere ){
                    
                //     var material = new THREE.LineBasicMaterial({
                //         color: 0x00ff00
                //     });
                    
                //     var geometry = new THREE.Geometry();
                //     geometry.vertices.push(
                //         travelCameraPoints[ currentFrame ].clone(),
                //         travelControlsPoints[ currentFrame ].clone()
                //     );
                    
                //     var line = new THREE.Line( geometry, material );
                //     this.debugSphere.add( line );

                // }

                this.camera.position.copy( travelCameraPoints[ currentFrame ] );
                this.controls.target.copy( travelControlsPoints[ currentFrame ] );

                config.onProgress( currentFrameAlpha, linearAlpha );

                this.controls.update();

            } else {

                this.camera.position.copy( end.camera.clone() );
                this.controls.target.copy( end.controls.clone() );

                this.animations.drop('cameraTravel');

                config.onEnd({
                    start: start,
                    end: end,
                    steps: frames
                });


                this.onEnd();

                this.debugSphere = null;
            }

            currentFrame++;

        });

    }

}

