import React from "react";
import styles from "./fptab.module.scss";

const FPTab = ({ title, img, mounted, ...props }) => {
  return (
    <div className={mounted ? `${styles.container} ${styles.container_active}`: styles.container}>
      <h2 className={styles.tab__title}>{title}</h2>
      <img src={img} className={styles.tab__img} alt={title} />
    </div>
  );
};

export default FPTab;
