import * as THREE from "three";

import OrbitControls from "./../../lib/three.orbitcontrols";
import FBXLoader from "three-fbx-loader";
import { isDev } from "../../lib/dev";
import CameraTraveler from "../../lib/camera.traveler";
import anime from "animejs";

export default class Scene {
  scene = new THREE.Scene();
  loader = new FBXLoader();
  camera;
  cameraDefaultY = 100;
  travelPointCount = 350;

  constructor(props) {
    this.props = props;

    this.init();
    this.animate();
    this.onWindowResize();
    this.onTransitionEnd = f => f;

    this.cameraTraveler = new CameraTraveler({
      camera: this.camera,
      controls: this.controls
    });
    // console.log(this);
  }

  init = () => {
    const { node, cameraPosition, modelPosition } = this.props;

    // ===============================================================camera
    this.camera = new THREE.PerspectiveCamera(
      50,
      node.offsetWidth / node.offsetHeight,
      0.01,
      1000
    );
    this.camera.position.set(...cameraPosition);

    // ===============================================================lights
    this.light = new THREE.HemisphereLight(0xffffff, 0x444444);
    this.light.position.set(0, 200, 0);
    this.scene.add(this.light);

    this.light_1 = new THREE.DirectionalLight(0xffffff);
    this.light_1.position.set(53.604, 23.278, -27.916);
    this.scene.add(this.light_1);

    this.loader.load(
      "models/WorldMap/earth_2.fbx",
      ob => {
        ob.children.map(o =>
          o.name === "BodyModel" || o.name === "nurbsToPoly1Model"
            ? (o.visible = false)
            : null
        );
        ob.position.set(...modelPosition);

        // ob.children[1].rotation.set(-1.3000000000000005, -3.1999999999999966, -1.3500000000000005);
        ob.scale.set(0.8, 0.8, 0.8);

        this.loader.load(
          "models/WorldMap/plane.fbx",
          plane => {
            this.airplaneWrapper = new THREE.Object3D();
            this.airplaneContainer = new THREE.Object3D();

            const mat = new THREE.MeshBasicMaterial({ color: 0xff0000 });
            const geo = new THREE.SphereGeometry(1.5, 8, 8);
            const testsphere = new THREE.Mesh(geo, mat);
            testsphere.position.set(14, 0, 0);
            this.testSphere = testsphere;
            this.airplaneContainer.add(testsphere);

            const testObj = new THREE.Object3D();
            this.testObj = testObj;
            this.airplaneContainer.add(testObj);

            this.AIRPLANE_ANGLE_PATH = [];

            this.airplane = plane;

            plane.children[0].rotation.y = -Math.PI / 2;

            this.airplaneWrapper.add(this.airplane);
            this.airplaneWrapper.add(this.airplaneContainer);
            this.airplaneContainer.add(this.airplane);
            this.airplane.rotation.set(0, Math.PI, 0);

            this.scene.add(this.airplaneWrapper);

            this.airplaneWrapper.visible = false;
          },
          null,
          e => console.log(e)
        );

        this.scene.add(ob);
        this.ob = ob;
        this.obSphere = ob.children[1];

        this.obSphere.geometry.computeBoundingSphere();
        const radius = this.obSphere.geometry.boundingSphere.radius;

        this.sphereProperties = {
          radius
        };

        // isDev && window.addEventListener('keydown', (e) => {
        //   const r = this.ob.children[1].rotation
        //   switch(e.code) {
        //     case 'ArrowUp': {
        //       this.ob.children[1].rotation.set(r.x + 0.05, r.y, r.z)
        //       break
        //     }
        //     case 'ArrowDown': {
        //       this.ob.children[1].rotation.set(r.x - 0.05, r.y, r.z)
        //       break
        //     }

        //     case 'ArrowLeft': {
        //       this.ob.children[1].rotation.set(r.x , r.y - 0.05, r.z)
        //       break
        //     }
        //     case 'ArrowRight': {
        //       this.ob.children[1].rotation.set(r.x, r.y + 0.05, r.z)
        //       break
        //     }

        //     case 'KeyA': {
        //       this.ob.children[1].rotation.set(r.x , r.y , r.z + 0.05)
        //       break
        //     }
        //     case 'KeyS': {
        //       this.ob.children[1].rotation.set(r.x, r.y , r.z - 0.05)
        //       break
        //     }

        //     default: {

        //     }
        //   }
        // })
      },
      null,
      e => console.log(e)
    );

    // ===============================================================renderer
    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(node.offsetWidth, node.offsetHeight);
    this.renderer.shadowMap.enabled = true;

    node.appendChild(this.renderer.domElement);

    // ===============================================================controls
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);

    this.controls.dampingFactor = 1;
    this.controls.enableDamping = true;

    if (!isDev) {
      this.controls.enableZoom = false;
      this.controls.enablePan = false;
      this.controls.right = null;
    }

    this.controls.enabled = true;
    this.controls.autoRotate = false;

    this.controls.update();

    window.addEventListener("resize", this.onWindowResize, false);
    node.addEventListener("mousedown", () => {
      if (this.controls.enabled) this.controls.autoRotate = false;
    });

    window.addEventListener("mouseup", () => {
      if (this.controls.enabled) this.controls.autoRotate = true;
    });
  };

  onWindowResize = () => {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  };

  onSelectFrom(latlng) {
    const dotPos = this.calcPosFromLatLonRad(
      ...latlng,
      this.sphereProperties.radius
    );

    if (this.fromDot) {
      if (this.toDot) this.scene.remove(this.toDot);
      this.clearLine();
      this.clearPlane();
    } else {
      this.fromDot = new THREE.Mesh(
        new THREE.SphereGeometry(2, 32, 32),
        new THREE.MeshBasicMaterial({ color: 0xff0000 })
      );
      this.scene.add(this.fromDot);
    }

    this.fromDot.position.copy(dotPos.clone());

    const crossPoints = this.getCrossPoints(this.camera, dotPos);

    this.travelCamera(crossPoints);
  }

  onSelectTo(latlng) {
    if (latlng) {
      const dotPos = this.calcPosFromLatLonRad(
        ...latlng,
        this.sphereProperties.radius
      );
      this.toDot = new THREE.Mesh(
        new THREE.SphereGeometry(2, 32, 32),
        new THREE.MeshBasicMaterial({ color: 0xff0000 })
      );

      this.toDot.position.copy(dotPos.clone());
      this.scene.add(this.toDot);

      const crossPoints = this.getCrossPoints(this.fromDot, dotPos);

      this.activeCrossPoints = crossPoints.map(item => item.clone());

      this.planeTransition = {
        crossPoints: this.activeCrossPoints.filter(
          (_, i, arr) => i < arr.length / 2
        ),
        crossPoints2: this.activeCrossPoints.filter(
          (_, i, arr) => i >= arr.length / 2
        )
      };

      this.initLine(dotPos, crossPoints);

      this.travelCamera(crossPoints);
    } else {
      if (this.toDot) this.scene.remove(this.toDot);
      this.clearLine();
      this.clearPlane();
    }
  }

  onSelectPreparing() {
    const targetDot = this.activeCrossPoints[
      Math.round(this.activeCrossPoints.length / 2)
    ];
    const crossPoints = this.getCrossPoints(this.camera, targetDot);

    this.travelCamera(crossPoints);

    this.planeTransition = {
      ...this.planeTransition,
      current: 0,
      target: this.planeTransition.crossPoints.length
    };

    const p1 = this.planeTransition.crossPoints[0];
    const p2 = this.planeTransition.crossPoints[1];

    this.placePlane(p1, p2);

    this.animatePlane = true;
  }

  onSelectReturning() {
    this.planeTransition = {
      ...this.planeTransition,
      crossPoints: this.planeTransition.crossPoints2,
      current: 0,
      target: this.planeTransition.crossPoints2.length
    };

    this.animatePlane = true;

    this.onTransitionEnd = () => {
      anime({
        targets: this.airplane.scale,
        x: [1, 0.01],
        y: [1, 0.01],
        z: [1, 0.01],
        duration: 3000,
        easing: "linear"
      });

      // this.onTransitionEnd=f=>f
    };
    this.scalePlaneByTime = true;
  }

  rotateAirplaneForPoints(p1, p2, airp) {
    const vectorForQuaternion = airp.position.clone();
    const vectorForQuaternion2 = airp.position.clone();
    const correctionRotationEuler = new THREE.Euler(
      Math.PI / 2,
      -Math.PI / 2,
      -Math.PI / 2
    );
    vectorForQuaternion2.normalize();
    vectorForQuaternion2.negate();
    vectorForQuaternion2.applyEuler(correctionRotationEuler);
    vectorForQuaternion2.normalize();

    var rotationMatrix = new THREE.Matrix4();
    var position = new THREE.Vector3(1000, 1000, 0);
    var angle = Math.PI / 4;
    var axis = new THREE.Vector3(1, 0.5, 0).normalize();

    rotationMatrix.makeRotationAxis(axis, angle).multiplyVector3(position);

    const finallyLookTarget = vectorForQuaternion.add(vectorForQuaternion2);
    airp.lookAt(finallyLookTarget);
  }

  angle_triangle(p1, p2, p3) {
    const num =
      (p2.x - p1.x) * (p3.x - p1.x) +
      (p2.y - p1.y) * (p3.y - p1.y) +
      (p2.z - p1.z) * (p3.z - p1.z);

    const den =
      Math.sqrt(
        Math.pow(p2.x - p1.x, 2) +
          Math.pow(p2.y - p1.y, 2) +
          Math.pow(p2.z - p1.z, 2)
      ) *
      Math.sqrt(
        Math.pow(p3.x - p1.x, 2) +
          Math.pow(p3.y - p1.y, 2) +
          Math.pow(p3.z - p1.z, 2)
      );

    const angle = Math.acos(num / den);

    return angle;
  }

  rotatePlane(airplaneWrapper, p1, p2) {
    const p1n = p1.clone();
    p1n.normalize();
    this.airplane.up.copy(p1n);

    this.airplane.position.copy(p1);
    this.airplane.lookAt(p2);
  }

  placePlane(p1, p2) {
    this.rotatePlane(this.airplaneWrapper, p1, p2);
    this.airplaneWrapper.visible = true;
  }

  planeAnimation() {
    if (this.planeTransition.current < this.planeTransition.target) {
      if (this.scalePlaneByTime) {
        const p =
          1 - this.planeTransition.current / this.planeTransition.target;

        this.airplane.scale.set(p, p, p);
      }

      if (this.planeTransition.current === 1) {
      }

      let p1, p2;

      if (this.planeTransition.crossPoints[this.planeTransition.current + 1]) {
        p1 = this.planeTransition.crossPoints[this.planeTransition.current];
        p2 = this.planeTransition.crossPoints[this.planeTransition.current + 1];
      } else if (
        this.planeTransition.crossPoints[this.planeTransition.current - 1]
      ) {
        p1 = this.planeTransition.crossPoints[this.planeTransition.current - 1];
        p2 = this.planeTransition.crossPoints[this.planeTransition.current];
      }

      this.placePlane(p1, p2);

      this.planeTransition.current++;
    } else {
      this.planeTransition.current = 0;

      this.animatePlane = false;
    }
  }

  initLine = (pos, crossPoints) => {
    const geometry = new THREE.Geometry();

    const curvePath = new THREE.CatmullRomCurve3(crossPoints);
    const lineGeometry = new THREE.TubeGeometry(curvePath, 36, 1, 3);

    const material = new THREE.MeshBasicMaterial({
      color: 0xff0000,
      side: THREE.DoubleSide
    });
    crossPoints.forEach(item => geometry.vertices.push(item.clone()));

    const lineMesh = new THREE.Mesh(lineGeometry, material);
    this.lineMesh = lineMesh;
    this.scene.add(lineMesh);
  };

  clearLine() {
    if (this.lineMesh) {
      this.lineMesh.visible = false;
      this.scene.remove(this.lineMesh);
      this.lineMesh = null;
    }
  }

  clearPlane() {
    this.scalePlaneByTime = false;
    if (this.airplaneWrapper) this.airplaneWrapper.visible = false;
  }

  travelCamera = crosPoints => {
    const generatedCameraPoints = crosPoints.map(item => {
      const targetCamPos = item.clone();
      const vLength = item.length();

      return {
        camera: targetCamPos.multiplyScalar(1 + 150 / vLength).toArray(),
        controls: [0, 0, 0]
      };
    });

    this.cameraTraveler.travel({
      start: {
        controls: [0, 0, 0],
        camera: this.camera.position.toArray(),
        crossPoints: {
          out: generatedCameraPoints
        }
      },
      end: generatedCameraPoints.pop(),
      speed: 3
    });
  };

  getCrossPoints(from, to) {
    return Array(this.travelPointCount)
      .fill(null)
      .map((_, i) => {
        const normalizedPos = to
          .clone()
          .lerp(from.position, 1 - i / this.travelPointCount)
          .clone()
          .normalize();
        const nextPos = normalizedPos
          .clone()
          .multiplyScalar(this.sphereProperties.radius);
        return nextPos;
      });
  }

  calcPosFromLatLonRad(lat, lon, radius) {
    var cosLat = Math.cos((lat * Math.PI) / 180.0);
    var sinLat = Math.sin((lat * Math.PI) / 180.0);
    var cosLon = Math.cos((lon * Math.PI) / 180.0);
    var sinLon = Math.sin((lon * Math.PI) / 180.0);

    return new THREE.Vector3(
      radius * cosLat * cosLon,
      radius * cosLat * sinLon,
      radius * sinLat
    ).applyEuler(new THREE.Euler(1.8, 3.25, 1.3500000000000005));
  }

  // ==================================================================globeDOTS

  disebledRotation = () => {
    if (this.controls.autoRotate) {
      this.controls.autoRotate = false;
      this.controls.enabled = isDev ? true : false;
      this.controls.maxPolarAngle = Math.PI;
      this.controls.minPolarAngle = 0;
      this.controls.update();
    } else return;
  };

  enabledRotation = () => {
    if (!this.controls.autoRotate) {
      this.controls.maxPolarAngle = Math.PI / 2;
      this.controls.minPolarAngle = Math.PI / 3;
      this.controls.autoRotate = isDev ? false : true;
      this.controls.enabled = true;
      this.controls.update();
    } else return;
  };

  updatePlanetSize() {
    // console.log("blur: ", this.blurScene);
    // console.log("fullsize:", this.fullSizePlanet);

    if (this.blurScene) {
      if (this.ob.scale.x <= 1.5) {
        this.ob.scale.x += 0.005;
        this.ob.scale.y += 0.005;
        this.ob.scale.z += 0.005;
        this.controls.target.y += 1;
      }
    } else {
      if (this.controls.target.y >= 0) {
        this.controls.target.y -= 1;
      }
      if (this.fullSizePlanet && this.ob.scale.x >= 1) {
        this.ob.scale.x -= 0.005;
        this.ob.scale.y -= 0.005;
        this.ob.scale.z -= 0.005;
      } else if (this.fullSizePlanet && this.ob.scale.x <= 1) {
        this.ob.scale.x += 0.005;
        this.ob.scale.y += 0.005;
        this.ob.scale.z += 0.005;
      } else if (!this.fullSizePlanet && this.ob.scale.x > 0.8) {
        this.ob.scale.x -= 0.005;
        this.ob.scale.y -= 0.005;
        this.ob.scale.z -= 0.005;
      }
    }
  }

  clearScene() {
    this.clearLine();
    this.clearPlane();

    if (this.fromDot) {
      this.scene.remove(this.fromDot);
      this.fromDot = null;
    }

    if (this.toDot) {
      this.scene.remove(this.toDot);
      this.toDot = null;
    }

    this.animatePlane = false;
    this.planeTransition = {};
  }

  backToRestingState = () => {};

  animate = () => {
    requestAnimationFrame(this.animate);
    this.controls.update();
    this.renderer.render(this.scene, this.camera);
    this.cameraTraveler && this.cameraTraveler.animate();
    if (this.ob) this.updatePlanetSize();
    if (this.controls.autoRotate) this.backToRestingState();

    if (this.isGlobeAnimating) this.animateGlobeToNextLocation();
    if (this.animatePlane) this.planeAnimation();
  };
}
