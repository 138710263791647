import React from "react";
import Container from "../components/Container/Container";
import ThanksCont from "../components/ThanksCont/ThanksCont";

const Thanks = () => {
  return (
    <>
      <Container>
        <ThanksCont />
      </Container>
    </>
  );
};

export default Thanks;
