import React from "react";
import styles from "./logo.module.scss";

const Logo = () => {
  return (
    <div className={styles.logo}>
      <img
        src="img/logo.png"
        srcSet="img/logo@2x.png 2x,
        img/logo@3x.png 3x"
        alt="logo"
      />
    </div>
  );
};

export default Logo;
