import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import Container from "../components/Container/Container";
import FPFSStepFirst from "../components/FPFSStepFirst/FPFSStepFirst";
import FPFSStepTwo from "../components/FPFSStepTwo/FPFSStepTwo";
import FPFSStepThree from "../components/FPFSStepThree/FPFSStepThree";
import FPFSStepFour from "../components/FPFSStepFour/FPFSStepFour";
import FPFSContactDetails from "../components/FPFSContactDetails/FPFSContactDetails";
import FPFSComplete from "../components/FPFSComplete/FPFSComplete";
import { changeStep } from "../redux/actions/step";
import { addFpApi } from "../redux/actions/fpApi";
import StepMove from "../components/StepMove/StepMove";

import Scena from "../components/Scena/";

const FPFirstScreen = ({
  intl,
  currentStep,
  changeStep,
  fl,
  fp,
  addFpApi,
  ...props
}) => {
  // ============================add fullpage Api to redux
  useEffect(() => {
    if (fl === undefined) return;
    if (!fp) addFpApi(fl);
  }, [fl, fp, addFpApi]);

  // ============================ fullpage off
  const closeScroll = useCallback(() => {
    if (fp)
      currentStep > 1
        ? fp.setAllowScrolling(false)
        : fp.setAllowScrolling(true);
  }, [fp, currentStep]);

  useEffect(() => {
    closeScroll();
  }, [closeScroll]);
  // ===========================renderStep

  const renderStep = () => {
    return (
      <div className="relativeCont">
        <FPFSStepFirst mounted={currentStep === 1} />
        <FPFSStepTwo mounted={currentStep === 2} />
        {/* <FPFSStepThree mounted={currentStep === 1} /> */}
        <FPFSStepThree mounted={currentStep === 3} />
        <FPFSStepFour mounted={currentStep === 4} />
        <FPFSContactDetails mounted={currentStep === 5} />
        <FPFSComplete mounted={currentStep === 6} />
      </div>
    );
  };
  // ===========================renderStep

  return (
    <>
      <Container>
        <Scena />
        {renderStep()}
        {currentStep > 1 && currentStep < 6 ? <StepMove /> : null}
      </Container>
    </>
  );
};

const mapStateToProps = ({ step, fp }) => ({
  currentStep: step.currentStep,
  fp
});
const mapDispatchToProps = {
  changeStep,
  addFpApi
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(FPFirstScreen));
