import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import TextInput from "../TextInput/TextInput";
import Textarea from "../Textarea/Textarea";

import { sendMailActions } from "../../redux/actions/sendMailActions";

import styles from "./contactsform.module.scss";
import { PATH } from "../../constants/utils";

const initData = {
  fname: "",
  lname: "",
  email: "",
  message: ""
};

const ContactsForm = ({ intl, sendMailActions, ...props }) => {
  const [formData, setFormData] = useState(initData);
  const [errors, setErrors] = useState({});

  // ===============================================================VALIDATION FUNC
  const validation = data => {
    const err = {};
    if (!data.fname) err.fname = "fname must be not blank";
    if (/^\d{1,}$/.test(data.fname.trim()))
      err.fname = "fname must be not number";
    if (data.fname.trim() === "") err.fname = "fname must be not blank";

    if (!data.lname) err.lname = "lname must be not blank";
    if (/^\d{1,}$/.test(data.lname.trim()))
      err.lname = "lname must be not number";
    if (data.lname.trim() === "") err.lname = "message must be not blank";

    if (!data.email) err.email = "email must be not blank";
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i.test(data.email))
      err.email = "email must be like email";

    if (!data.message) err.message = "message must be not blank";
    if (data.message.trim() === "") err.message = "message must be not blank";

    return err;
  };

  // ===============================================================CHANGE FUNC
  const handleChange = useCallback(
    ({ target }) => {
      setErrors(() => {
        for (let i in errors) {
          if (i === target.name) {
            delete errors[i];
          }
        }
        return { ...errors };
      });

      setFormData({ ...formData, [target.name]: target.value });
    },
    [formData, errors]
  );

  // ===============================================================SUBMIT FUNC
  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      const er = validation(formData);
      setErrors({ ...errors, ...er });

      if (Object.keys(er).length === 0) {
        sendMailActions(formData);
        setFormData(initData);
        props.history.push(`${PATH}thanks`);
        return;
      }
    },
    [formData, errors, sendMailActions, props]
  );

  return (
    <div className={styles.container}>
      <div className={styles.social}>
        <a href="http://" className={styles.social_item}>
          <p>WeChat</p>
          <img src="img/qr-code.png" alt="WeChat" />
        </a>
        <a
          href="https://www.linkedin.com/company/iware-travel/"
          className={styles.social_item}
        >
          <p>LinkedIn</p>
          <img src="img/linked-in-c.png" alt="LinkedIn" />
        </a>
      </div>

      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.form_title}>
          {intl.formatMessage({ id: "contactUs.formTitle" })}
        </div>
        <div className={styles.form_50}>
          <TextInput
            name="fname"
            onChange={handleChange}
            value={formData.fname}
            placeholder={intl.formatMessage({ id: "contactUs.fn" })}
            error={errors.fname}
          />
        </div>
        <div className={styles.form_50}>
          <TextInput
            name="lname"
            onChange={handleChange}
            value={formData.lname}
            placeholder={intl.formatMessage({ id: "contactUs.ln" })}
            error={errors.lname}
          />
        </div>
        <div className={styles.form_100}>
          <TextInput
            name="email"
            onChange={handleChange}
            value={formData.email}
            placeholder={intl.formatMessage({ id: "contactUs.email" })}
            error={errors.email}
          />
        </div>
        <div className={styles.form_100}>
          <Textarea
            name="message"
            onChange={handleChange}
            value={formData.message}
            placeholder={intl.formatMessage({ id: "contactUs.message" })}
            error={errors.message}
          />
        </div>
        <div className={styles.form_100}>
          <button className={styles.btn} onSubmit={handleSubmit}>
            {intl.formatMessage({ id: "contactUs.send" })}
          </button>
        </div>
      </form>
    </div>
  );
};
const mapStateToProps = () => ({});
const mapDispatchToProps = { sendMailActions };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ContactsForm));
