import ACTION from "../../constants/constants";

export const choosePurpose = purpose => ({
  type: ACTION.CHOOSE_PURPOSE,
  purpose
});

export const clearPurpose = () => ({
  type: ACTION.CLEAR_PURPOSE
});

export const addTraveler = ({
  id,
  fname,
  lname,
  dateMonth,
  dateDay,
  dateYear
}) => ({
  type: ACTION.ADD_TREVELERS,
  traveler: { id, fname, lname, dateMonth, dateDay, dateYear }
});

export const removeTraveler = id => ({
  type: ACTION.REMOVE_TREVELERS,
  travelerId: id
});

export const clearTraveler = () => ({
  type: ACTION.CLEAR_TREVELERS
});

export const addFears = fears => ({
  type: ACTION.ADD_FEARS,
  fears
});

export const clearFears = () => ({
  type: ACTION.CLEAR_FEARS
});
