import React, { useCallback, useEffect } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

import Title from "../Title/Title";
import styles from "./fpfscomplete.module.scss";

import { changeStep } from "../../redux/actions/step";

import TagManager from "react-gtm-module";

const FPFSComplete = ({ intl, changeStep, step, mounted, ...props }) => {
  // =====================================START ADD GTM
  useEffect(() => {
    if (mounted && window.ga) {
      const data = {
        dataLayer: {
          hitType: "event",
          eventCategory: "FunnelStep",
          eventAction: "view",
          eventLabel: "Quote Submitted"
        }
      };
      TagManager.dataLayer(data);
      // window.ga("send", {
      //   hitType: "event",
      //   eventCategory: "FunnelStep",
      //   eventAction: "view",
      //   eventLabel: "Quote Submitted"
      // });
    }
  }, [mounted]);
  // =====================================END ADD GTM

  const handleClick = useCallback(() => {
    changeStep(1);
  }, [changeStep]);

  return (
    <div
      className={
        mounted
          ? `${styles.container} ${styles.container_active}`
          : styles.container
      }
    >
      <Title
        title={intl.formatMessage({ id: "step.complite.title" })}
        string={intl.formatMessage({ id: "step.complite.string" })}
      />
      <div className={styles.btn_cont}>
        <button className={styles.btn} onClick={handleClick}>
          {intl.formatMessage({ id: "step.complite.btn" })}
        </button>
      </div>
    </div>
  );
};
const mapStateToProps = ({ currentStep }) => ({
  step: currentStep
});
const mapDispatchToProps = {
  changeStep
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(FPFSComplete));
