import ACTIONS from "../../constants/constants";

export const changeStep = currentStep => ({
  type: ACTIONS.CHANGE_STEP,
  currentStep
});

export const blockNextStep = active => ({
  type: ACTIONS.BLOCK_STEP,
  active
});

export const setBlockFields = active => ({
  type: ACTIONS.BLOCK_TPFIELDS,
  active
});
