import React, { useState, useCallback } from "react";
import { injectIntl } from "react-intl";

import styles from "./fpthreescreen.module.scss";

import Container from "../../components/Container/Container";
import Title from "../../components/Title/Title";
import CommentBody from "../../components/CommentBody/CommentBody";

const FPThreeScreen = ({ intl, ...props }) => {
  const comments = [
    {
      name: intl.formatMessage({ id: "comment.name.1" }),
      comment: intl.formatMessage({ id: "comment.1" }),
      photo: "img/people/Al.png"
    },
    {
      name: intl.formatMessage({ id: "comment.name.2" }),
      comment: intl.formatMessage({ id: "comment.2" }),
      photo: "img/people/Elizabeth.png"
    },
    {
      name: intl.formatMessage({ id: "comment.name.3" }),
      comment: intl.formatMessage({ id: "comment.3" }),
      photo: "img/people/Lee.png"
    }
    // {
    //   name: intl.formatMessage({ id: "comment.name.4" }),
    //   comment: intl.formatMessage({ id: "comment.4" }),
    //   photo: "img/people/Luan.png"
    // }
  ];

  const [active, setActive] = useState(1);
  // =============================================================next
  const nextSlid = useCallback(() => {
    if (active < comments.length) {
      setActive(a => a + 1);
    } else {
      setActive(1);
    }
  }, [active, comments]);
  // =============================================================prev
  const prevSlid = useCallback(() => {
    if (active <= 1) {
      setActive(comments.length);
    } else {
      setActive(a => a - 1);
    }
  }, [active, comments]);
  // =============================================================toggle
  const toggleSlid = useCallback(
    ({ target }) => {
      setActive(parseInt(target.id.slice(0, 1)));
    },
    [setActive]
  );
  // =============================================================render comments
  const renderComments = () => {
    switch (active) {
      case 1:
        return (
          <CommentBody name={comments[0].name} comment={comments[0].comment} />
        );
      case 2:
        return (
          <CommentBody name={comments[1].name} comment={comments[1].comment} />
        );
      case 3:
        return (
          <CommentBody name={comments[2].name} comment={comments[2].comment} />
        );
      // case 4:
      //   return (
      //     <CommentBody name={comments[3].name} comment={comments[3].comment} />
      //   );
      default:
        return (
          <CommentBody name={comments[0].name} comment={comments[0].comment} />
        );
    }
  };

  return (
    <>
      <Container>
        <Title
          title={intl.formatMessage({ id: "fp.screenThree.title" })}
          subtitle={intl.formatMessage({ id: "fp.screenThree.subtitle" })}
        />
        <div className={styles.container}>
          <div className={styles.photos}>
            {comments.map((p, i) => (
              <div
                onClick={toggleSlid}
                key={p.photo + "_key"}
                id={i + 1 + "_ph"}
                className={
                  active === i + 1
                    ? `${styles.photo} ${styles.photo_active}`
                    : styles.photo
                }
              >
                <img src={p.photo} alt="people" />
              </div>
            ))}

            <button
              className={`${styles.btn} ${styles.btn_prev}`}
              onClick={prevSlid}
            ></button>
            <button
              className={`${styles.btn} ${styles.btn_next}`}
              onClick={nextSlid}
            ></button>
          </div>

          <div className={styles.comment}>{renderComments()}</div>
        </div>
      </Container>
    </>
  );
};

export default injectIntl(FPThreeScreen);
