export default {
  CHANGE_STEP: "CHANGE_STEP",
  BLOCK_STEP: "BLOCK_STEP",

  ADD_FP_API: "ADD_FP_API",

  TOGGLE_NAVIGATION: "TOGGLE_NAVIGATION",

  CHOOSE_PURPOSE: "CHOOSE_PURPOSE",
  CLEAR_PURPOSE: "CLEAR_PURPOSE",

  SELECT_COUNTRY_FROM: "SELECT_COUNTRY_FROM",
  SELECT_PROVINCES_TO: "SELECT_PROVINCES_TO",
  SELECT_DEPARTING_DATE: "SELECT_DEPARTING_DATE",
  SELECT_RETURNING_DATE: "SELECT_RETURNING_DATE",
  CLEAR_TRAVEL_PLAN: "CLEAR_TRAVEL_PLAN",
  BLOCK_TPFIELDS: "BLOCK_TPFIELDS",

  ADD_TREVELERS: "ADD_TREVELERS",
  REMOVE_TREVELERS: "REMOVE_TREVELERS",
  CLEAR_TREVELERS: "CLEAR_TREVELERS",

  ADD_FEARS: "ADD_FEARS",
  CLEAR_FEARS: "CLEAR_FEARS",

  BEGIN_API_CALL: "BEGIN_API_CALL",
  API_CALL_ERROR: "API_CALL_ERROR",
  SEND_MAIL: "SEND_MAIL",

  ADD_SCENE_API: "ADD_SCENE_API",

  LOAD_COUNTRY_SUCCESS: "LOAD_COUNTRY_SUCCESS"
};
