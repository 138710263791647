import ACTION from "../../constants/constants";
import * as countryApi from "../../api/countryApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export const loadCountries = () => dispatch => {
  dispatch(beginApiCall());
  return countryApi
    .getCountries()
    .then(countries =>
      dispatch({
        type: ACTION.LOAD_COUNTRY_SUCCESS,
        payload: countries.sort((a, b) => a.name.toLowerCase() === 'china' ? -1 : +1)
      })
    )
    .catch(err => {
      dispatch(apiCallError());
      throw err;
    });
};
