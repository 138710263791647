import React, { useState, useCallback, useEffect, memo } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import Title from "../Title/Title";
import TextInput from "../TextInput/TextInput";

import { changeStep } from "../../redux/actions/step";
import { sendTravelPlanActions } from "../../redux/actions/sendMailActions";
import { clearTraveler, clearPurpose } from "../../redux/actions/travelers";
import { clearTravelPlan } from "../../redux/actions/travelPlan";

// import TagManager from "react-gtm-module";

import styles from "./fpfscontactdetails.module.scss";

const initState = {
  first_name: "",
  last_name: "",
  email: "",
  phone: ""
};

const FPFSContactDetails = ({
  intl,
  changeStep,
  purpose,
  travelPlan,
  travelers,
  sendTravelPlanActions,
  mounted,
  clearTraveler,
  clearPurpose,
  clearTravelPlan,
  ...props
}) => {
  const [formData, setFormData] = useState(initState);
  const [errors, setErrors] = useState({});

  // =====================================START ADD GTM
  // useEffect(() => {
  //   if (mounted && window.ga) {
  //     const data = {
  //       dataLayer: {
  //         formStep: "submitquote"
  //       }
  //     };
  //     TagManager.dataLayer(data);

  //   }
  // }, [mounted]);
  // =====================================END ADD GTM

  // =========================================add names to fields
  useEffect(() => {
    if (travelers.length > 0) {
      setFormData(f => ({
        ...f,
        first_name: travelers[0].fname,
        last_name: travelers[0].lname
      }));
    }
  }, [travelers]);

  const validation = data => {
    const err = {};
    if (!data.first_name) err.first_name = "First Name is blank";
    if (!data.last_name) err.last_name = "Last Name is blank";
    if (!data.email) err.email = "Email is blank";
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i.test(data.email))
      err.email = "Email is not valid";

    return err;
  };

  const handleChange = useCallback(
    ({ target }) => {
      setErrors(() => {
        for (let i in errors) {
          if (i === target.name) {
            delete errors[i];
          }
        }
        return { ...errors };
      });
      setFormData({ ...formData, [target.name]: target.value });
    },
    [formData, errors]
  );

  const handleSubmitt = e => {
    e.preventDefault();

    const error = validation(formData);
    setErrors({ ...errors, ...error });

    if (Object.keys(error).length === 0) {
      const stateOb = {
        contacts: formData,
        purpose,
        plan: travelPlan,
        travelers
      };
      clearPurpose();
      clearTravelPlan();
      clearTraveler();
      setFormData({ ...initState });
      sendTravelPlanActions(stateOb);
      changeStep(6);
    }
  };

  return (
    <div
      className={
        mounted
          ? `${styles.container} ${styles.container_active}`
          : styles.container
      }
    >
      <Title
        title={intl.formatMessage({ id: "step.contactDetail.title" })}
        string={intl.formatMessage({ id: "step.contactDetail.string" })}
      />
      <form className={styles.form} onSubmit={handleSubmitt}>
        <div className={styles.form__item}>
          <TextInput
            name="first_name"
            placeholder={intl.formatMessage({ id: "step.four.FName" })}
            value={formData.first_name}
            onChange={handleChange}
            error={errors.first_name ? true : false}
          />
        </div>
        <div className={styles.form__item}>
          <TextInput
            name="last_name"
            placeholder={intl.formatMessage({ id: "step.four.LName" })}
            value={formData.last_name}
            onChange={handleChange}
            error={errors.last_name ? true : false}
          />
        </div>
        <div className={styles.form__item}>
          <TextInput
            name="email"
            placeholder={intl.formatMessage({ id: "step.contactDetail.email" })}
            value={formData.email}
            onChange={handleChange}
            error={errors.email ? true : false}
          />
        </div>
        <div className={styles.form__item}>
          <TextInput
            name="phone"
            placeholder={intl.formatMessage({
              id: "step.contactDetail.phoneor"
            })}
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <div className={styles.form__btn}>
          <button className={styles.btn} onSubmit={handleSubmitt}>
            {intl.formatMessage({ id: "step.contactDetail.btn" })}
          </button>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = ({ purpose, travelPlan, traveler }) => ({
  purpose,
  travelPlan,
  travelers: traveler.travelers
});
const mapDispatchToProps = {
  changeStep,
  sendTravelPlanActions,
  clearTraveler,
  clearPurpose,
  clearTravelPlan
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(memo(FPFSContactDetails)));
