import ACTIONS from "../../constants/constants";

const initialState = false;

export default (state = initialState, action) => {
  if (action.type === ACTIONS.ADD_FP_API) {
    return action.api;
  }
  return state;
};
