import React, { useCallback, useState, useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import styles from "./stepmove.module.scss";

import { changeStep, blockNextStep } from "../../redux/actions/step";
import { clearTravelPlan } from "../../redux/actions/travelPlan";

const StepMove = ({
  intl,
  currentStep,
  changeStep,
  blockStep,
  blockNextStep,
  clearTravelPlan,
  ...props
}) => {
  const [mounted, setMounted] = useState(false);
  const [id, setId] = useState("");

  console.log(currentStep);

  const stepsName = [
    null,
    "purposeoftravel",
    "travelplan",
    "whostraveling",
    "almostdone"
  ];

  useEffect(() => {
    setId(() => stepsName[`${currentStep}`]);
  }, [currentStep, stepsName]);

  useEffect(() => {
    setMounted(false);
    setTimeout(() => setMounted(true), 1000);
  }, [currentStep]);

  // ===========================stepmove
  const toNextStep = useCallback(() => {
    if (blockStep) return;
    if (currentStep === 5) return;
    changeStep(currentStep + 1);
    blockNextStep(true);
  }, [changeStep, currentStep, blockStep, blockNextStep]);

  const toPrevStep = useCallback(() => {
    if (currentStep === 3) clearTravelPlan();
    changeStep(currentStep - 1);
  }, [changeStep, clearTravelPlan, currentStep]);
  // ===========================stepmove

  const dots = [2, 3, 4, 5];

  return (
    <div
      className={`${styles.btnContainer} ${
        !mounted ? styles.btnContainer_hidden : ""
      }`}
    >
      <button
        onClick={toPrevStep}
        className={styles.btn + " " + styles.btn__prev}
      >
        {intl.formatMessage({ id: "step.move.prev" })}
      </button>

      <div className={styles.dots}>
        {dots.map(d =>
          currentStep === d ? (
            <div
              key={d}
              className={styles.dots__item + " " + styles.dots__active}
            ></div>
          ) : (
            <div key={d} className={styles.dots__item}></div>
          )
        )}
      </div>

      <button
        onClick={toNextStep}
        id={id}
        className={
          currentStep === 5
            ? `${styles.btn} ${styles.btn__next} ${styles.btn_hide}`
            : `${styles.btn} ${styles.btn__next}`
        }
      >
        {intl.formatMessage({ id: "step.move.next" })}
      </button>
    </div>
  );
};

const mapStateToProps = ({ step, blockStep }) => ({
  currentStep: step.currentStep,
  blockStep
});
const mapDispatchToProps = {
  changeStep,
  blockNextStep,
  clearTravelPlan
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(StepMove));
