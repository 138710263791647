import React from "react";
import { injectIntl } from "react-intl";
import styles from "./contactuslink.module.scss";

const ContactUsLink = ({ intl, ...props }) => {
  return (
    <button className={styles.btn}>
      <span>{intl.formatMessage({ id: "contUsLink.title" })}</span>
      <span className={styles.bold}>
        {intl.formatMessage({ id: "contUsLink.subTitle" })}
      </span>
    </button>
  );
};

export default injectIntl(ContactUsLink);
