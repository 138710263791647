import React, { useState, useCallback } from "react";
import { connect } from "react-redux";

import { injectIntl } from "react-intl";
import styles from "./travelfearform.module.scss";

import MultiSelect from "../MultiSelect/MultiSelect";
import { addFears } from "../../redux/actions/travelers";
import { sendTravelerFears } from "../../redux/actions/sendMailActions";

const TravelFearForm = ({
  intl,
  addFears,
  f,
  hide,
  sendTravelerFears,
  ...props
}) => {
  const [fears, setFears] = useState(f.fears);

  const options = [
    intl.formatMessage({ id: "fp.screenTwo.fear1" }),
    intl.formatMessage({ id: "fp.screenTwo.fear2" }),
    intl.formatMessage({ id: "fp.screenTwo.fear3" }),
    intl.formatMessage({ id: "fp.screenTwo.fear4" })
  ];

  // ====================================================submit
  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      addFears(fears);
      if (fears.length > 0) {
        sendTravelerFears({ fears: fears });
        hide();
      }
    },
    [addFears, fears, hide, sendTravelerFears]
  );
  //======================================================select
  const handleChange = useCallback(
    () => e => {
      fears.includes(e.id)
        ? setFears(fears.filter(v => v !== e.id))
        : setFears([...fears, e.id]);
    },
    [fears]
  );
  // =================================================render
  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.title}>
        {intl.formatMessage({ id: "fp.screenTwo.fearFormTitle" })}
      </div>

      <div className={styles.select_container}>
        <MultiSelect
          name="biggest fear"
          placeholder={intl.formatMessage({ id: "fp.screenTwo.selectAnsver" })}
          options={options}
          value={fears}
          handleChange={handleChange()}
        />
      </div>

      <div className={styles.btn_container}>
        <button className={styles.btn} onSubmit={handleSubmit}>
          {intl.formatMessage({ id: "fp.screenTwo.submit" })}
        </button>
      </div>
    </form>
  );
};

const mapStateToProps = ({ fears }) => ({ f: fears });
const mapDispatchToProps = {
  addFears,
  sendTravelerFears
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(TravelFearForm));
