import ACTION from "../../constants/constants";

function typeEndsOnSuccess(type) {
  return type.substring(type.length - 8) === "_SUCCESS";
}

export default function(state = 0, action) {
  const { type } = action;
  if (type === ACTION.BEGIN_API_CALL) {
    return state + 1;
  } else if (type === ACTION.API_CALL_ERROR || typeEndsOnSuccess(type)) {
    return state - 1;
  }

  return state;
}
