import React, { useState, useCallback, useEffect } from "react";
import styles from "./select.module.scss";
import { Scrollbars } from "react-custom-scrollbars";

const MultiSelect = ({
  name = "",
  placeholder = "",
  options = [],
  value = [],
  handleChange,
  disabled = false,
  ...props
}) => {
  const [toggle, setToggle] = useState(false);
  // ======================================================Select
  const handleSel = ({ target }) => {
    handleChange(target);
  };
  // ======================================================Open
  const openOption = useCallback(() => {
    if (!toggle) setToggle(true);
  }, [setToggle, toggle]);
  // ======================================================Close
  const closeOption = useCallback(() => {
    if (toggle) setToggle(false);
  }, [setToggle, toggle]);
  // ======================================================CloseClick outside
  useEffect(() => {
    const f = e => {
      if (e.target.dataset.name === name) return;
      closeOption();
    };

    window.addEventListener("click", f);
    return () => {
      window.removeEventListener("click", f);
    };
  }, [closeOption, name]);
  // ===================================render
  return (
    <div
      className={
        !disabled
          ? value.length !== 0
            ? `${styles.select} ${styles.active}`
            : styles.select
          : `${styles.select} ${styles.select_disable}`
      }
      data-value={value[0]}
      data-name={name}
      onClick={openOption}
    >
      <div
        data-name={name}
        className={
          toggle ? styles.arrow : `${styles.arrow} ${styles.arrow__activ}`
        }
        onClick={closeOption}
      ></div>
      {value.length > 0 ? (
        <div className={`${styles.selected__option} ${styles.radio_active}`}>
          <span className={`${styles.radio}`}></span>
          <span>{value[0]}</span>
        </div>
      ) : null}
      {!toggle && value.length === 0 ? <>{placeholder}</> : null}
      {toggle ? (
        <div className={`${styles.option__container} dropdownSelect`}>
          <Scrollbars style={{ width: "100%", height: "100%" }}>
            <div>
              {options.map(o => (
                <div
                  onClick={handleSel}
                  className={
                    value.includes(o)
                      ? `${styles.option} ${styles.radio_active}`
                      : styles.option
                  }
                  id={o}
                  key={o}
                  data-name={name}
                  title={o}
                >
                  <span className={`${styles.radio}`}></span>
                  <span>{o}</span>
                </div>
              ))}
            </div>
          </Scrollbars>
        </div>
      ) : null}
    </div>
  );
};

export default MultiSelect;
