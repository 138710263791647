import React from "react";
import styles from "./commentbody.module.scss";

const CommentBody = ({ name, comment, ...props }) => {
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{name}</h3>

      <div className={styles.body}>{comment}</div>
    </div>
  );
};

export default CommentBody;
