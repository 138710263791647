import ACTION from "../../constants/constants";

export const selectCountryFrom = ({ name, flag, latlng }) => ({
  type: ACTION.SELECT_COUNTRY_FROM,
  from: { name, flag, latlng }
});

export const selectProvincesTo = provinces => ({
  type: ACTION.SELECT_PROVINCES_TO,
  to: provinces
});

export const selectDepartingDate = ({
  DEPARTING_DATE_month,
  DEPARTING_DATE_day,
  DEPARTING_DATE_year
}) => ({
  type: ACTION.SELECT_DEPARTING_DATE,
  DEPARTING_DATE_month,
  DEPARTING_DATE_day,
  DEPARTING_DATE_year
});
// -------------------------------
export const selectReturningDate = ({
  RETURNING_DATE_month,
  RETURNING_DATE_day,
  RETURNING_DATE_year
}) => ({
  type: ACTION.SELECT_RETURNING_DATE,
  RETURNING_DATE_month,
  RETURNING_DATE_day,
  RETURNING_DATE_year
});

export const clearTravelPlan = () => ({
  type: ACTION.CLEAR_TRAVEL_PLAN
});
