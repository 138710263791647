import React from "react";
import { injectIntl } from "react-intl";
import Container from "../components/Container/Container";
import Title from "../components/Title/Title";
import ContactsForm from "../components/ContactsForm/ContactsForm";

const ContactUsPage = ({ intl, ...props }) => {
  return (
    <>
      <Container>
        <Title
          title={intl.formatMessage({ id: "contactUs.title" })}
          subtitle={intl.formatMessage({ id: "contactUs.subTitle" })}
        />
        <ContactsForm history={props.history} />
      </Container>
    </>
  );
};

export default injectIntl(ContactUsPage);
