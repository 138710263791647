import React, { memo, useCallback, useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

import { loadCountries } from "../../redux/actions/countries";

import styles from "./fpfsstepthree.module.scss";

import Title from "../Title/Title";
import MySelect from "../MySelect/MySelect";
import SelectCountry from "../SelectCountry/SelectCountry";
import MultiSelect from "../MultiSelect/MultiSelect";

import TagManager from "react-gtm-module";

import {
  selectCountryFrom,
  selectProvincesTo,
  selectDepartingDate,
  selectReturningDate
} from "../../redux/actions/travelPlan";
import { blockNextStep, setBlockFields } from "../../redux/actions/step";

const options = [
  "Alberta",
  "British Columbia",
  "Manitoba",
  "New Brunswick",
  "Newfoundland and Labrador ",
  "Northwest Territories",
  "Nova Scotia",
  "Nunavut ",
  "Ontario",
  "Prince Edward Island ",
  "Quebec",
  "Saskatchewan ",
  "Yukon"
];

const opened = {
  dep_d: false,
  dep_m: false,
  dep_y: false,
  ret_d: false,
  ret_m: false,
  ret_y: false
};

const FPFSStepThree = ({
  intl,
  country,
  loadCountries,
  selectCountryFrom,
  selectProvincesTo,
  selectDepartingDate,
  selectReturningDate,
  travelPlan,
  mounted,
  step,
  blockNextStep,
  blockFields,
  setBlockFields,
  ...props
}) => {
  let today = new Date();

  const [openedSelect, setOpenedSelect] = useState(opened);
  const [retYear, setRetYear] = useState(today.getFullYear());
  const [disabled, setDisabled] = useState({
    to: true,
    departing: true,
    returning: true
  });
  // =====================================START ADD GTM
  useEffect(() => {
    if (mounted && window.ga) {
      const data = {
        dataLayer: {
          hitType: "event",
          eventCategory: "FunnelStep",
          eventAction: "view",
          eventLabel: "Travel Plan"
        }
      };
      TagManager.dataLayer(data);
      // window.ga("send", {
      //   hitType: "event",
      //   eventCategory: "FunnelStep",
      //   eventAction: "view",
      //   eventLabel: "Travel Plan"
      // });
    }
  }, [mounted]);
  // =====================================END ADD GTM
  useEffect(() => {
    if (step === 3) {
      travelPlan.DEPARTING_DATE_year !== ""
        ? setRetYear(travelPlan.DEPARTING_DATE_year)
        : setRetYear(today.getFullYear());
    }
  }, [step, today, travelPlan.DEPARTING_DATE_year]);

  // let disabled = {
  //   to: !Object.keys(travelPlan.from).length,
  //   departing: travelPlan.to.length === 0,
  //   returning: travelPlan.DEPARTING_DATE_month === "" && travelPlan.DEPARTING_DATE_day === "" && travelPlan.DEPARTING_DATE_year === ""
  // }

  useEffect(() => {
    if (blockFields) {
      setDisabled(prev => ({
        ...prev,
        to: true,
        departing: true,
        returning: true
      }));
    }
  }, [blockFields]);

  useEffect(() => {
    if (Object.keys(travelPlan.from).length !== 0) {
      setBlockFields(false);
    }
  }, [travelPlan, setBlockFields]);

  useEffect(() => {
    if (step === 3 && !blockFields) {
      if (Object.keys(travelPlan.from).length !== 0)
        setDisabled(prev => ({ ...prev, to: false }));
      if (travelPlan.to.length !== 0)
        setDisabled(prev => ({ ...prev, departing: false }));
      if (
        travelPlan.DEPARTING_DATE_month !== "" &&
        travelPlan.DEPARTING_DATE_day !== "" &&
        travelPlan.DEPARTING_DATE_year !== ""
      )
        setDisabled(prev => ({ ...prev, returning: false }));
    }
  }, [step, travelPlan, blockFields]);

  useEffect(() => {
    if (step === 3) {
      // travelPlan.to.length > 0
      //   ? setOpenedSelect(prev => ({ ...prev, dep_m: true }))
      //   : setOpenedSelect(prev => ({ ...prev, dep_m: false }));

      travelPlan.DEPARTING_DATE_month !== ""
        ? setOpenedSelect(prev => ({ ...prev, dep_d: true }))
        : setOpenedSelect(prev => ({ ...prev, dep_d: false }));

      travelPlan.DEPARTING_DATE_day !== ""
        ? setOpenedSelect(prev => ({ ...prev, dep_y: true }))
        : setOpenedSelect(prev => ({ ...prev, dep_y: false }));

      travelPlan.DEPARTING_DATE_year !== ""
        ? setOpenedSelect(prev => ({ ...prev, ret_m: true }))
        : setOpenedSelect(prev => ({ ...prev, ret_m: false }));

      travelPlan.RETURNING_DATE_month !== ""
        ? setOpenedSelect(prev => ({ ...prev, ret_d: true }))
        : setOpenedSelect(prev => ({ ...prev, ret_d: false }));
      travelPlan.RETURNING_DATE_day !== ""
        ? setOpenedSelect(prev => ({ ...prev, ret_y: true }))
        : setOpenedSelect(prev => ({ ...prev, ret_y: false }));
    }
  }, [step, travelPlan]);

  // ================================================blockNextStep
  useEffect(() => {
    if (step === 3) {
      Object.keys(travelPlan.from).length === 0 ||
      travelPlan.to.length === 0 ||
      travelPlan.DEPARTING_DATE_month === "" ||
      travelPlan.DEPARTING_DATE_day === "" ||
      travelPlan.DEPARTING_DATE_year === "" ||
      travelPlan.RETURNING_DATE_month === "" ||
      travelPlan.RETURNING_DATE_day === "" ||
      travelPlan.RETURNING_DATE_year === ""
        ? blockNextStep(true)
        : blockNextStep(false);
    }
  }, [blockNextStep, step, travelPlan]);
  // ================================================getCountryList
  useEffect(() => {
    if (country.length === 0) loadCountries();
  }, [country.length, loadCountries]);

  // ================================================selectDATE
  const handleChange = useCallback(
    () => e => {
      selectDepartingDate({
        ...travelPlan,
        [e.dataset.name]: e.dataset.value
      });
      selectReturningDate({
        ...travelPlan,
        [e.dataset.name]: e.dataset.value
      });
    },
    [travelPlan, selectReturningDate, selectDepartingDate]
  );

  // ================================================selectCOUNTRY
  const selectCounry = useCallback(
    () => e => {
      selectCountryFrom({
        name: e.dataset.value,
        flag: e.dataset.img,
        latlng: e.dataset.latlng.split(",").map(item => +item)
      });
    },
    [selectCountryFrom]
  );

  // ================================================selectPROVINCES
  const selectProvinces = useCallback(
    () => e => {
      travelPlan.to.includes(e.id)
        ? selectProvincesTo(travelPlan.to.filter(v => v !== e.id))
        : selectProvincesTo([...travelPlan.to, e.id]);
    },
    [travelPlan, selectProvincesTo]
  );

  return (
    <div
      className={
        mounted
          ? `${styles.container} ${styles.container_active}`
          : styles.container
      }
    >
      <Title title={intl.formatMessage({ id: "step.three.title" })} />

      <div
        className={
          mounted ? `${styles.form} ${styles.form_active}` : styles.form
        }
      >
        <div className={styles.form__item}>
          <h3 className={styles.title}>
            {intl.formatMessage({ id: "step.three.from" })}
          </h3>

          <div className={styles.selCont}>
            <SelectCountry
              val={travelPlan.from}
              name="from"
              options={country}
              handleChange={selectCounry()}
              placeholder={intl.formatMessage({
                id: "step.three.selectCountry"
              })}
            />
          </div>

          <h3 className={styles.title}>
            {intl.formatMessage({ id: "step.three.departingDate" })}
          </h3>

          <div className={styles.dateCont}>
            <div className={styles.dateCont__min}>
              <MySelect
                val={travelPlan.DEPARTING_DATE_month}
                name="DEPARTING_DATE_month"
                min="1"
                max="12"
                placeholder={intl.formatMessage({ id: "step.four.month" })}
                handleChange={handleChange()}
                disabled={disabled.departing}
                opened={openedSelect.dep_m}
              />
            </div>
            <div className={styles.dateCont__min}>
              <MySelect
                val={travelPlan.DEPARTING_DATE_day}
                name="DEPARTING_DATE_day"
                type="number"
                min="1"
                max="31"
                placeholder={intl.formatMessage({ id: "step.four.day" })}
                handleChange={handleChange()}
                disabled={disabled.departing}
                opened={openedSelect.dep_d}
              />
            </div>
            <div className={styles.dateCont__max}>
              <MySelect
                val={travelPlan.DEPARTING_DATE_year}
                name="DEPARTING_DATE_year"
                type="number"
                min={today.getFullYear()}
                max={today.getFullYear() + 10}
                placeholder={intl.formatMessage({ id: "step.four.year" })}
                handleChange={handleChange()}
                disabled={disabled.departing}
                opened={openedSelect.dep_y}
              />
            </div>
          </div>
        </div>

        <div className={styles.form__item}>
          <h3 className={styles.title}>
            {intl.formatMessage({ id: "step.three.to" })}
          </h3>

          <div className={styles.selCont}>
            <MultiSelect
              name="to"
              placeholder={intl.formatMessage({
                id: "step.three.chooseProvince"
              })}
              options={options}
              value={travelPlan.to}
              handleChange={selectProvinces()}
              disabled={disabled.to}
            />
          </div>

          <h3 className={styles.title}>
            {intl.formatMessage({ id: "step.three.returningDate" })}
          </h3>

          <div className={styles.dateCont}>
            <div className={styles.dateCont__min}>
              <MySelect
                val={travelPlan.RETURNING_DATE_month}
                name="RETURNING_DATE_month"
                type="number"
                min="1"
                max="12"
                placeholder={intl.formatMessage({ id: "step.four.month" })}
                handleChange={handleChange()}
                disabled={disabled.returning}
                opened={openedSelect.ret_m}
              />
            </div>
            <div className={styles.dateCont__min}>
              <MySelect
                val={travelPlan.RETURNING_DATE_day}
                name="RETURNING_DATE_day"
                type="number"
                min="1"
                max="31"
                placeholder={intl.formatMessage({ id: "step.four.day" })}
                handleChange={handleChange()}
                disabled={disabled.returning}
                opened={openedSelect.ret_d}
              />
            </div>
            <div className={styles.dateCont__max}>
              <MySelect
                val={travelPlan.RETURNING_DATE_year}
                name="RETURNING_DATE_year"
                type="number"
                min={parseInt(retYear)}
                max={parseInt(retYear) + 10}
                placeholder={intl.formatMessage({ id: "step.four.year" })}
                handleChange={handleChange()}
                disabled={disabled.returning}
                opened={openedSelect.ret_y}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ country, travelPlan, step, blockFields }) => ({
  country,
  travelPlan,
  step: step.currentStep,
  blockFields
});

const mapDispatchToProps = {
  loadCountries,
  selectCountryFrom,
  selectProvincesTo,
  selectDepartingDate,
  selectReturningDate,
  blockNextStep,
  setBlockFields
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(memo(FPFSStepThree)));
