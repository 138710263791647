import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

import Container from "../../components/Container/Container";
import Title from "../../components/Title/Title";

import styles from "./fpscreenfour.module.scss";

const FPScreenFour = ({ intl, fp, ...props }) => {
  const moveToTop = () => {
    fp.moveTo("firstPage", 0);
  };

  return (
    <>
      <Container>
        <div className={styles.clouds}></div>
        <Title title={intl.formatMessage({ id: "fp.screenFour.title" })} />
        <div className={styles.container}>
          <div className={styles.video}>
            <video controls="controls">
              <source src="videos/iWare.mp4" type="video/mp4" />
            </video>
          </div>
          <div className={styles.info}>
            <p className={styles.bold}>
              {intl.formatMessage({ id: "fp.screenFour.bold" })}
            </p>
            <p className={styles.norm}>
              {intl.formatMessage({ id: "fp.screenFour.norm" })}
            </p>
          </div>
          <button className={styles.btn} onClick={moveToTop}>
            {intl.formatMessage({ id: "fp.screenFour.btn" })}
          </button>
        </div>
      </Container>
    </>
  );
};
const mapStateToProps = ({ fp }) => ({ fp });
export default connect(mapStateToProps)(injectIntl(FPScreenFour));
