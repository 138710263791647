import React from "react";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";

import styles from "./thanks.module.scss";
import { PATH } from "../../constants/utils";

const ThanksCont = ({ intl, ...props }) => {
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>
        {intl.formatMessage({ id: "thanks.title" })}
      </h3>
      <p className={styles.text}>{intl.formatMessage({ id: "thanks.text" })}</p>
      <Link to={`${PATH}contacts`}>
        <button className={styles.btn}>
          {intl.formatMessage({ id: "thanks.btn" })}
        </button>
      </Link>
    </div>
  );
};

export default injectIntl(ThanksCont);
