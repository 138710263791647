/* eslint-disable */

export function getTwoV3DirectionByAlpha( v3_1 , v3_2 , alpha  ) {
    const v1 = v3_1.clone();
    const v2 = v3_2.clone();
    v1.normalize();
    v2.normalize();
    // v1.multiplyScalar( 1 - alpha );
    // v2.multiplyScalar( alpha );
    const vResult = v1.lerp( v2, alpha );
    vResult.normalize();
    return vResult;
}

export function getPointsOverSphere( options ) {

    const { 
        boundingSphere, 
        cameraPosition_start, 
        cameraPosition_end,
        targetPosition_start,
        targetPosition_end,
        crossPointsCount,
    } = options;
    const boundingSphereCenter = boundingSphere.center;

    const cameraStartDistanceToCeneter = boundingSphereCenter.clone().distanceTo( cameraPosition_start );
    // const cameraStartDistanceToCeneter_radiusAspect = cameraStartDistanceToCeneter / boundingSphereRadius;
    const cameraEndDistanceToCeneter = boundingSphereCenter.clone().distanceTo( cameraPosition_end );
    // const cameraEndDistanceToCeneter_radiusAspect = cameraEndDistanceToCeneter / boundingSphereRadius;

    const targetStartDistanceToCeneter = boundingSphereCenter.clone().distanceTo( targetPosition_start );
    // const targetStartDistanceToCeneter_radiusAspect = targetStartDistanceToCeneter / boundingSphereRadius;
    const targetEndDistanceToCeneter = boundingSphereCenter.clone().distanceTo( targetPosition_end );
    // const targetEndDistanceToCeneter_radiusAspect = targetEndDistanceToCeneter / boundingSphereRadius;

    // not sure about order of 
    const cameraStartV = cameraPosition_start.clone().sub( boundingSphereCenter.clone() );
    const cameraEndV = cameraPosition_end.clone().sub( boundingSphereCenter.clone() );

    const targetStartV = targetPosition_start.clone().sub( boundingSphereCenter.clone() );
    const targetEndV = targetPosition_end.clone().sub( boundingSphereCenter.clone() );

    const targetPoints = [];
    const cameraPoints = [];

    for( let i = 0; i < crossPointsCount; i++ ){

        const currentOperationAlpha = i / crossPointsCount;


        // calculate camera
        const currentCameraNormalizedDirection = getTwoV3DirectionByAlpha( cameraStartV, cameraEndV, currentOperationAlpha );
        const currentCameraDistance = cameraStartDistanceToCeneter + ( cameraEndDistanceToCeneter - cameraStartDistanceToCeneter )  * currentOperationAlpha;
        const realCameraVector = currentCameraNormalizedDirection.multiplyScalar( currentCameraDistance );
        const nextCameraPosition = boundingSphereCenter.clone().add( realCameraVector );
        cameraPoints.push( nextCameraPosition );

        // calculate target
        const currentTargetNormalizedDirection = getTwoV3DirectionByAlpha( targetStartV, targetEndV, currentOperationAlpha );
        const currentTargetDistance = targetStartDistanceToCeneter + ( targetEndDistanceToCeneter - targetStartDistanceToCeneter )  * currentOperationAlpha;
        const realTargetVector = currentTargetNormalizedDirection.multiplyScalar( currentTargetDistance );
        const nextTargetPosition = boundingSphereCenter.clone().add( realTargetVector );
        targetPoints.push( nextTargetPosition );

    }


    const start = {
        camera: cameraPosition_start.toArray(),
        controls: targetPosition_start.toArray(),
        crossPoints: {
            out: [],
            in: []
        }
    };

    // fix for duplications
    cameraPoints.shift();
    targetPoints.shift();

    while( cameraPoints.length ){
        start.crossPoints.out.push( {
            camera: cameraPoints.shift().toArray(),
            controls: targetPoints.shift().toArray(),
        } );
    }
    
    const end = {
        camera: cameraPosition_end.toArray(),
        controls: targetPosition_end.toArray(),
    };

    return {
        start, end
    };

}