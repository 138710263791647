import React, { useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import Title from "../Title/Title";
import Travelers from "../Travelers/Travelers";
import styles from "./fpfssteptwo.module.scss";

import { setBlockFields } from "../../redux/actions/step";

import TagManager from "react-gtm-module";

const FPFSStepTwo = ({ intl, mounted, setBlockFields, ...props }) => {
  // =====================================START ADD GTM
  useEffect(() => {
    if (mounted && window.ga) {
      const data = {
        dataLayer: {
          hitType: "event",
          eventCategory: "FunnelStep",
          eventAction: "view",
          eventLabel: "Purpose of Travel"
        }
      };
      TagManager.dataLayer(data);

      // window.ga("send", {
      //   hitType: "event",
      //   eventCategory: "FunnelStep",
      //   eventAction: "view",
      //   eventLabel: "Purpose of Travel"
      // });
    }
  }, [mounted]);
  // =====================================END ADD GTM

  useEffect(() => {
    if (mounted) {
      setBlockFields(true);
    }
  }, [mounted, setBlockFields]);

  return (
    <div
      className={
        mounted
          ? `${styles.container} ${styles.container_active}`
          : styles.container
      }
    >
      <Title title={intl.formatMessage({ id: "step.two.title" })} />
      <Travelers mounted={mounted} />
    </div>
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = { setBlockFields };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(FPFSStepTwo));
