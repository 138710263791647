import ACTIONS from "../../constants/constants";

const initialState = false;

export default (state = initialState, action) => {
  if (action.type === ACTIONS.TOGGLE_NAVIGATION) {
    return action.active;
  }
  return state;
};
