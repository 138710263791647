import React, { memo } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { NavLink } from "react-router-dom";

import { toggleNav } from "../../redux/actions/nav";
import { changeStep } from "../../redux/actions/step";
import { clearTraveler, clearPurpose } from "../../redux/actions/travelers";
import { clearTravelPlan } from "../../redux/actions/travelPlan";

import Burger from "../Burger/Burger";

import styles from "./navigation.module.scss";
import { PATH } from "../../constants/utils";

const Navigation = ({
  intl,
  nav,
  toggleNav,
  clearTraveler,
  clearPurpose,
  clearTravelPlan,
  changeStep,
  ...props
}) => {
  const closeNav = ({ target }) => {
    toggleNav(false);
    if (target.id !== "home-link") clearSteps();
  };

  const clearSteps = () => {
    clearPurpose();
    clearTravelPlan();
    clearTraveler();
    changeStep(1);
  };

  return (
    <div
      className={
        nav ? `${styles.container} ${styles.active}` : styles.container
      }
    >
      <div className={styles.burger_cont}>
        <Burger />
      </div>

      <div className={styles.nav}>
        <NavLink
          exact
          to={PATH}
          className="nav-link"
          activeClassName="nav-link-active"
          onClick={closeNav}
          id="home-link"
        >
          {intl.formatMessage({ id: "nav.home" })}
        </NavLink>
        <NavLink
          to={`${PATH}contacts`}
          className="nav-link"
          activeClassName="nav-link-active"
          onClick={closeNav}
        >
          {intl.formatMessage({ id: "nav.contacts" })}
        </NavLink>
      </div>

      <div className={styles.social}>
        <a className={styles.social_iten} href="https://#">
          <img src="img/zhihu.png" alt="Zhihu" />
        </a>
        <a className={styles.social_iten} href="https://#">
          <img src="img/we-chat.png" alt="WeChat" />
        </a>
        <a
          className={styles.social_iten}
          href="https://www.linkedin.com/company/iware-travel/"
        >
          <img src="img/linked-in.png" alt="Linkedin" />
        </a>
      </div>

      <div className={styles.license}>
        <div className={styles.license_title}>
          {intl.formatMessage({ id: "nav.license" })}
        </div>
        <img
          src="img/broker-license-provider.png"
          alt="broker-license-provider"
        />
      </div>

      <div className={styles.copy}>
        © 2019 <span>iWare</span>Travel.ca
      </div>
    </div>
  );
};

const mapStateToProps = ({ nav }) => ({
  nav
});

const mapDispatchToProps = {
  toggleNav,
  clearTraveler,
  clearPurpose,
  clearTravelPlan,
  changeStep
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(memo(Navigation)));
