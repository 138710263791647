import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import { choosePurpose } from "../../redux/actions/travelers";
import { blockNextStep } from "../../redux/actions/step";

import styles from "./travelers.module.scss";

const Travelers = ({
  intl,
  purpose,
  choosePurpose,
  mounted,
  blockStep,
  blockNextStep,
  step,
  ...props
}) => {
  const handleChoosePurpose = useCallback(
    ({ target }) => {
      choosePurpose(target.id);
    },
    [choosePurpose]
  );

  useEffect(() => {
    if (step === 2) {
      purpose === "" ? blockNextStep(true) : blockNextStep(false);
    }
  }, [purpose, blockNextStep, step]);

  return (
    <div
      className={
        mounted
          ? `${styles.container} ${styles.container_active}`
          : styles.container
      }
    >
      <div
        className={
          mounted
            ? `${styles.container__item} ${styles.container__item_active}`
            : styles.container__item
        }
      >
        <button
          className={
            purpose === "OUTDOOR ADVENTURE"
              ? `${styles.traveler} ${styles.active}`
              : `${styles.traveler}`
          }
          onClick={handleChoosePurpose}
          id="OUTDOOR ADVENTURE"
        >
          <span className={styles.title}>
            {intl.formatMessage({ id: "step.two.treveler_1" })}
          </span>
          <img
            src="img/travelers/outdoor-adventure.png"
            srcSet="img/travelers/outdoor-adventure@2x.png 2x,
                  img/travelers/outdoor-adventure@3x.png 3x"
            alt="traveler"
          />
        </button>
      </div>

      <div
        className={
          mounted
            ? `${styles.container__item} ${styles.container__item_active}`
            : styles.container__item
        }
      >
        <button
          className={
            purpose === "VISITING FAMILY"
              ? `${styles.traveler} ${styles.active}`
              : `${styles.traveler}`
          }
          onClick={handleChoosePurpose}
          id="VISITING FAMILY"
        >
          <span className={styles.title}>
            {intl.formatMessage({ id: "step.two.treveler_2" })}
          </span>
          <img
            src="img/travelers/visiting-family.png"
            srcSet="img/travelers/visiting-family@2x.png 2x,
                  img/travelers/visiting-family@3x.png 3x"
            alt="traveler"
          />
        </button>
      </div>

      <div
        className={
          mounted
            ? `${styles.container__item} ${styles.container__item_active}`
            : styles.container__item
        }
      >
        <button
          className={
            purpose === "STUDYING IN CANADA"
              ? `${styles.traveler} ${styles.active}`
              : `${styles.traveler}`
          }
          onClick={handleChoosePurpose}
          id="STUDYING IN CANADA"
        >
          <span className={styles.title}>
            {intl.formatMessage({ id: "step.two.treveler_3" })}
          </span>
          <img
            src="img/travelers/student.png"
            srcSet="img/travelers/student@2x.png 2x,
                  img/travelers/student@3x.png 3x"
            alt="traveler"
          />
        </button>
      </div>

      <div
        className={
          mounted
            ? `${styles.container__item} ${styles.container__item_active}`
            : styles.container__item
        }
      >
        <button
          className={
            purpose === "EXPLORING OPPORTUNITIES"
              ? `${styles.traveler} ${styles.active}`
              : `${styles.traveler}`
          }
          onClick={handleChoosePurpose}
          id="EXPLORING OPPORTUNITIES"
        >
          <span className={styles.title}>
            {intl.formatMessage({ id: "step.two.treveler_4" })}
          </span>
          <img
            src="img/travelers/exploring-opportunities.png"
            srcSet="img/travelers/exploring-opportunities@2x.png 2x,
                  img/travelers/exploring-opportunities@3x.png 3x"
            alt="traveler"
          />
        </button>
      </div>
    </div>
  );
};
const mapStateToProps = ({ purpose, blockStep, step }) => ({
  purpose: purpose.purpose,
  blockStep,
  step: step.currentStep
});
const mapDispatchToProps = {
  choosePurpose,
  blockNextStep
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Travelers));
