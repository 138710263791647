import ACTIONS from "../../constants/constants";

const initialState = {
  from: {},
  to: [],

  DEPARTING_DATE_month: "",
  DEPARTING_DATE_day: "",
  DEPARTING_DATE_year: "",

  RETURNING_DATE_month: "",
  RETURNING_DATE_day: "",
  RETURNING_DATE_year: ""
};

let now = new Date();

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SELECT_COUNTRY_FROM:
      return {
        ...initialState,
        from: action.from,
        to: []
      };
    case ACTIONS.SELECT_PROVINCES_TO:
      return {
        ...state,
        to: action.to,

        DEPARTING_DATE_month: "",
        DEPARTING_DATE_day: "",
        DEPARTING_DATE_year: "",

        RETURNING_DATE_month: "",
        RETURNING_DATE_day: "",
        RETURNING_DATE_year: ""
      };

    case 'clear':
      console.log('cleared')
      
      return initialState
      
    case ACTIONS.SELECT_DEPARTING_DATE:

      if (
        parseInt(action.DEPARTING_DATE_month) <= parseInt(now.getMonth()) + 1 &&
        parseInt(action.DEPARTING_DATE_day) <= parseInt(now.getDate()) &&
        parseInt(action.DEPARTING_DATE_year) <= parseInt(now.getFullYear())
      ) {
        return {
          ...state,
          DEPARTING_DATE_month: "",
          DEPARTING_DATE_day: "",
          DEPARTING_DATE_year: ""
        };
      } else
        return {
          ...state,
          DEPARTING_DATE_month: action.DEPARTING_DATE_month,
          DEPARTING_DATE_day: action.DEPARTING_DATE_day,
          DEPARTING_DATE_year: action.DEPARTING_DATE_year
        };
    case ACTIONS.SELECT_RETURNING_DATE:
      if (
        parseInt(action.RETURNING_DATE_month) <=
          parseInt(state.DEPARTING_DATE_month) &&
        parseInt(action.RETURNING_DATE_day) <=
          parseInt(state.DEPARTING_DATE_day) &&
        parseInt(action.RETURNING_DATE_year) ===
          parseInt(state.DEPARTING_DATE_year)
      ) {
        return {
          ...state,
          RETURNING_DATE_month: "",
          RETURNING_DATE_day: "",
          RETURNING_DATE_year: ""
        };
      } else
        return {
          ...state,
          RETURNING_DATE_month: action.RETURNING_DATE_month,
          RETURNING_DATE_day: action.RETURNING_DATE_day,
          RETURNING_DATE_year: action.RETURNING_DATE_year
        };
    case ACTIONS.CLEAR_TRAVEL_PLAN:
      return {
        ...initialState
      };

    default:
      return state;
  }
};
