import React, { useState, useCallback, useEffect } from "react";
import styles from "./select.module.scss";
import { Scrollbars } from "react-custom-scrollbars";

const SelectCoutry = ({
  name = "",
  placeholder = "",
  options = [],
  handleChange,
  val = {},
  search = true,
  ...props
}) => {
  const [toggle, setToggle] = useState(false);
  const [filterValue, setFilterValue] = useState(null);
  // const inp = useRef(null);

  const filterItems = options =>
    filterValue && search
      ? options.filter(item =>
          item.name.toLowerCase().includes(filterValue.toLowerCase())
        )
      : options;

  const handleSearch = ({ target }) => {
    setFilterValue(target.value);
  };

  const handleSel = ({ target }) => {
    handleChange(target);
    setToggle(false);
  };

  const openOption = useCallback(() => {
    if (!toggle) setToggle(true);
  }, [setToggle, toggle]);

  const closeOption = useCallback(() => {
    if (toggle) setToggle(false);
  }, [setToggle, toggle]);

  useEffect(() => {
    const f = e => {
      if (e.target.dataset.name === name) return;
      closeOption();
    };

    window.addEventListener("click", f);
    return () => {
      window.removeEventListener("click", f);
    };
  }, [closeOption, name]);

  useEffect(() => {
    if (val.name) {
      setFilterValue(null);
    }
  }, [toggle, val]);

  return (
    <div
      className={val.name ? `${styles.select} ${styles.active}` : styles.select}
      data-value={val.name}
      data-name={name}
      onClick={openOption}
    >
      <div
        className={
          toggle ? styles.arrow : `${styles.arrow} ${styles.arrow__activ}`
        }
        onClick={closeOption}
      ></div>
      {val.name && !toggle ? (
        <div className={styles.selected__option}>
          <img src={val.flag} alt="flag" />
          <span>{val.name}</span>
        </div>
      ) : null}
      {!toggle && !val.name ? <div>{placeholder}</div> : null}
      {toggle ? (
        <input
          type="text"
          data-name={name}
          onChange={handleSearch}
          className={styles.input}
          placeholder="search..."
        />
      ) : null}
      {toggle ? (
        <div className={`${styles.option__container} dropdownSelect`}>
          <Scrollbars style={{ width: "100%", height: "100%" }}>
            <div>
              {filterItems(options).map(o => (
                <div
                  onClick={handleSel}
                  data-name={name}
                  data-value={o.name}
                  className={styles.option}
                  id={o.name}
                  key={o.name}
                  data-img={o.flag}
                  data-latlng={o.latlng}
                >
                  <img src={o.flag} alt={o.name} />
                  <span>{o.name} </span>
                </div>
              ))}
            </div>
          </Scrollbars>
        </div>
      ) : null}
    </div>
  );
};

export default SelectCoutry;
