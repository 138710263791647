import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ReactFullpage from "@fullpage/react-fullpage";
import FPFirstScreen from "../Screens/FPFirstScreen";
import FPTwoScreen from "../Screens/FPTwoScreen/FPTwoScreen";
import FPThreeScreen from "../Screens/FPThreeScreen/FPThreeScreen";
import FPScreenFour from "../Screens/FPScreenFour/FPScreenFour";
import ContactUsLink from "../components/ContactUsLink/ContactUsLink";

import useWidth from "../hooks/useWidth";
import { PATH } from "../constants/utils";

const Fullpage = ({ step }) => (
  <ReactFullpage
    licenseKey={"YOUR_KEY_HERE"}
    navigation={true}
    anchors={["firstPage", "secondPage", "thirdPage", "lastPage"]}
    navigationPosition={"left"}
    keyboardScrolling={false}
    normalScrollElements={".dropdownSelect"}
    render={({ state, fullpageApi }) => {
      return (
        <ReactFullpage.Wrapper>
          <div className="section">
            <FPFirstScreen fl={fullpageApi} />
          </div>
          <div className="section">
            <FPTwoScreen />
          </div>
          <div className="section">
            <FPThreeScreen />
          </div>
          <div className="section">
            <FPScreenFour />
          </div>

          <style>{`#fp-nav{ display: ${
            step > 1 ? "none !important" : "block"
          }}`}</style>
        </ReactFullpage.Wrapper>
      );
    }}
  />
);

const HomePage = ({ step, ...props }) => {
  let width = useWidth();

  return (
    <>
      <Fullpage step={step} />
      {width > 991 ? (
        <Link to={`${PATH}contacts`}>
          <ContactUsLink />
        </Link>
      ) : null}
    </>
  );
};
const mapStateToProps = ({ step }) => ({
  step: step.currentStep
});
export default connect(mapStateToProps)(HomePage);
