import React, { memo } from "react";
import PropTypes from "prop-types";

import styles from "./textarea.module.scss";

const Textarea = ({
  name = "",
  onChange,
  value = "",
  error,
  maxLength,
  rows,
  placeholder = "",
  ...props
}) => {
  return (
    <textarea
      className={
        !error ? `${styles.textarea}` : `${styles.textarea} ${styles.error} `
      }
      name={name}
      value={value}
      maxLength={maxLength}
      rows={rows}
      onChange={onChange}
      placeholder={placeholder}
    ></textarea>
  );
};

Textarea.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  placholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string
};

export default memo(Textarea);
