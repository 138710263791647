import React,{useRef, useEffect} from "react";
import {connect} from 'react-redux'
import PropTypes from "prop-types";
import styles from "./title.module.scss";
import anime from 'animejs'


const Title = ({ title, subtitle, string,step,...props }) => {
  const allTitle=useRef(null);

  useEffect(() => {
    if(allTitle.current){
      anime({
        targets: allTitle.current,
        opacity: [0,1],
        duration: 500,
        delay:500,
        easing: 'easeInQuad',
      })
    }
  }, [step])

  return (
    <div ref={allTitle} style={{pointerEvents: 'none'}}>
      <h1 className={styles.title} >{title}</h1>
      {subtitle ? <h2 className={styles.subtitle}>{subtitle}</h2> : null}
      {string ? <h3 className={styles.string}>{string}</h3> : null}
    </div>
  );
};

Title.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
};

const mapStateToProps=({step})=>({
  step: step.currentStep
})
export default connect(mapStateToProps)(Title);
