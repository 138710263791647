import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import Title from "../Title/Title";
import { injectIntl } from "react-intl";
import style from "./fpfsstepfirst.module.scss";
import { changeStep } from "../../redux/actions/step";

const FPFSStepFirst = ({
  intl,
  changeStep,
  currentStep,
  mounted,
  fp,
  ...props
}) => {
  const [hover, setHover] = useState(false);
  const [m, setM] = useState(false);

  const toNextStep = useCallback(() => {
    changeStep(currentStep + 1);
  }, [changeStep, currentStep]);

  const handleToggleLink = e => {
    setHover(n => !hover);
  };

  const sectionDown = () => {
    fp.moveTo("secondPage", 0);
  };

  useEffect(() => {
    setM(true);
  }, []);

  return (
    <div
      className={
        mounted && m
          ? `${style.container} ${style.container_active}`
          : style.container
      }
    >
      <Title
        title={intl.formatMessage({ id: "step.first.title" })}
        subtitle={intl.formatMessage({ id: "step.first.subTitle" })}
      />
      <div
        className={
          mounted && m
            ? `${style.linkContainer}  ${style.linkContainer_active}`
            : style.linkContainer
        }
      >
        <button className={style.stepLink} onClick={toNextStep}>
          <span>{intl.formatMessage({ id: "step.first.linkTitle" })}</span>
          <span>{intl.formatMessage({ id: "step.first.linkSubTitle" })}</span>
        </button>

        <button
          className={style.insuranceLink}
          onMouseOver={handleToggleLink}
          onMouseOut={handleToggleLink}
          onClick={sectionDown}
        >
          <div className={style.contText}>
            <span className={style.insuranceLink__title}>
              {intl.formatMessage({ id: "step.first.insuresLinkTitle" })}
            </span>

            {hover ? (
              <ol className={style.insuranceLink__list}>
                <li>
                  {intl.formatMessage({
                    id: "step.first.insuresLinkList_f"
                  })}
                </li>
                <li>
                  {intl.formatMessage({
                    id: "step.first.insuresLinkList_s"
                  })}
                </li>
                <li>
                  {intl.formatMessage({
                    id: "step.first.insuresLinkList_t"
                  })}
                </li>
              </ol>
            ) : null}
          </div>
        </button>
      </div>
    </div>
  );
};
const mapStateToProps = ({ step, fp }) => ({
  currentStep: step.currentStep,
  fp
});

const mapDispatchToProps = {
  changeStep
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(FPFSStepFirst));
