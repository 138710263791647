import React from "react";
import Container from "../components/Container/Container";
import Title from "../components/Title/Title";

const PageNotFound = () => {
  return (
    <>
      <Container>
        <Title title="Error 404" subtitle="Page not found" />
      </Container>
    </>
  );
};

export default PageNotFound;
