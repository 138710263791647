import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import "./index.scss";
import App from "./App";
import store from "./redux/store";

import IntlWrapper from "./components/IntlWrapper";

import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-5GNH392"
};

TagManager.initialize(tagManagerArgs);

render(
  <Provider store={store}>
    <Router>
      <IntlWrapper>
        <App />
      </IntlWrapper>
    </Router>
  </Provider>,
  document.getElementById("root")
);
