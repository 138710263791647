import ACTION from "../../constants/constants";
import { isDev } from "../../lib/dev";

const initState = {
  travelers: [
    ...(isDev ? [{
      fname: 'dev',
      lname: 'dev',
      month: 1,
      day: 1,
      year: 2020
    }] : [])
  ]
};
export default (state = initState, action) => {
  switch (action.type) {
    case ACTION.ADD_TREVELERS:
      return {
        travelers: [
          ...state.travelers,
          {
            ...action.traveler,
            id: Math.floor(Date.now() / Math.random()) + "_id"
          }
        ]
      };
    case ACTION.REMOVE_TREVELERS:
      return {
        travelers: state.travelers.filter(t => t.id !== action.travelerId)
      };
    case ACTION.CLEAR_TREVELERS:
      return {
        ...initState
      };
    default:
      return state;
  }
};
