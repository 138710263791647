import * as Mail from "../../api/mailApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export const sendMailActions = contactus => dispatch => {
  dispatch(beginApiCall());
  return Mail.sendMessage(contactus)
    .then()
    .catch(err => {
      dispatch(apiCallError());
      throw err;
    });
};

export const sendTravelPlanActions = data => dispatch => {
  dispatch(beginApiCall());
  return Mail.sendTravelPlan(data)
    .then()
    .catch(err => {
      dispatch(apiCallError());
      throw err;
    });
};

export const sendTravelerFears = data => dispatch => {
  dispatch(beginApiCall());
  return Mail.sendFears(data)
    .then()
    .catch(err => {
      dispatch(apiCallError());
      throw err;
    });
};
