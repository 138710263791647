import React, { useState, useCallback, useEffect, memo } from "react";
import styles from "./myselect.module.scss";
import { Scrollbars } from "react-custom-scrollbars";

const MySelect = ({
  min = 0,
  max = 0,
  val = "",
  placeholder = "",
  name = "",
  opened = false,
  disabled = false,
  handleChange,
  error = "",
  ...props
}) => {
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    if (opened) setToggle(true);
  }, [opened]);

  let arr = [];
  for (let i = min; i <= max; i++) {
    if (i <= 9) {
      i = "0" + i;
    }
    arr.push(i);
  }

  const handleSel = ({ target }) => {
    handleChange(target);
    setToggle(false);
  };

  const openOption = useCallback(() => {
    if (!toggle) setToggle(true);
  }, [setToggle, toggle]);

  useEffect(() => {
    const f = e => {
      if (e.target.dataset.name === name) return;
      setToggle(false);
    };

    window.addEventListener("click", f);
    return () => {
      window.removeEventListener("click", f);
    };
  }, [setToggle, name]);

  const getRootClass = () => {
    if (disabled) {
      return `${styles.select} ${styles.select_disabled}`;
    } else if (error) {
      return `${styles.select} ${styles.select_error}`;
    } else if (val) {
      return `${styles.select} ${styles.active}`;
    }
    return styles.select;
  };

  return (
    <div
      className={getRootClass()}
      data-value={val}
      data-name={name}
      onClick={openOption}
    >
      {val && !toggle ? <>{val}</> : null}

      {!toggle && !val ? <>{placeholder}</> : null}

      {toggle ? (
        <div className={`${styles.option__container} dropdownSelect`}>
          <Scrollbars style={{ width: "98%", height: "100%" }}>
            <div>
              {arr.map(a => (
                <div
                  key={a}
                  onClick={handleSel}
                  data-name={name}
                  data-value={a}
                  id={a}
                  className={styles.option}
                >
                  {a}
                </div>
              ))}
            </div>
          </Scrollbars>
        </div>
      ) : null}
    </div>
  );
};

export default memo(MySelect);
