import React, { useState, useCallback, useEffect, useRef } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

import styles from "./fptwoscreen.module.scss";

import Container from "../../components/Container/Container";
import Title from "../../components/Title/Title";
import TravelFearForm from "../../components/TravelFearForm/TravelFearForm";
import FPTab from "../../components/FPTab/FPTab";

const FPTwoScreen = ({ intl, fp, ...props }) => {
  const [activeTab, setActiveTab] = useState(1);
  const [activeFear, setActiveFear] = useState(true);
  const contentQuantity = [1, 2, 3, 4, 5];
  let time = useRef(null);

  const hideFear = () => () => {
    setActiveFear(false);
  };

  const nextFear = useCallback(() => {
    time.current = setTimeout(() => {
      setActiveTab(tab => {
        let targetTab = tab + 1 === contentQuantity.length + 1 ? 1 : tab + 1;
        return targetTab;
      });
    }, 4000);
  }, [contentQuantity.length]);

  const handleTab = useCallback(
    ({ target }) => {
      setActiveTab(parseInt(target.id.slice(0, 1)));
      clearTimeout(time.current);
    },
    [setActiveTab, time]
  );

  useEffect(() => {
    nextFear();
    return () => {
      clearTimeout(time.current);
    };
  }, [activeTab, fp, nextFear, time]);

  const renderTAb = () => {
    return (
      <React.Fragment>
        {contentQuantity.map(item => (
          <FPTab
            key={parseInt(item) / Math.PI}
            title={intl.formatMessage({ id: `fp.screenTwo.fear${item}` })}
            img={`img/tabs/${item}.png`}
            mounted={activeTab === item}
          />
        ))}
      </React.Fragment>
    );
  };

  const renderBtns = () => {
    return (
      <React.Fragment>
        {contentQuantity.map(item => (
          <button
            className={
              activeTab === parseInt(item)
                ? `${styles.tabBtns__item} ${styles.active}`
                : styles.tabBtns__item
            }
            id={`${item}_id`}
            onClick={handleTab}
            key={parseInt(item) * Math.PI}
          >
            {item}
          </button>
        ))}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Container>
        <div className={`${styles.container} `}>
          <Title title={intl.formatMessage({ id: "fp.screenTwo.title" })} />

          <div className={styles.tabBtns}>{renderBtns()}</div>

          <div className={styles.tab__container}>{renderTAb()}</div>

          {activeFear ? <TravelFearForm hide={hideFear()} /> : null}
        </div>
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = ({ fp }) => ({
  fp
});
export default connect(mapStateToProps)(injectIntl(FPTwoScreen));
